import {React,useEffect,useState,createRef} from "react";
import { useParams  } from 'react-router-dom';
import { Col, Row, Input, Tabs  } from 'antd';
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Anchor } from 'antd';
import { Typography } from 'antd';

import 'antd/dist/antd.less'; // or 'antd/dist/antd.less'
import Footer from '../common/Footer'
import Header from '../common/Header'

import './Ruta.css';
import TopCard from "./topCard/topCard";
import InformacionBasica from "./informacionBasica/informacionBasica";
import ComoLlegar from "./comoLlegar/comoLlegar";
import ComoVereda from "./comoVereda/comoVereda";
import HelmetComponent from "../common/helmet/helmet";


const link_api = process.env.REACT_APP_API_MONGO_URL;

const Ruta = () => {
    const [isMobile, setIsMobile] = useState(false)
    const [isXLdesktop, setIsXLdesktop] = useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
    if (window.innerWidth >= 1200) {
        setIsXLdesktop(true)
    } else {
        setIsXLdesktop(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize();
  })


    const carouselRef = createRef();
    

    const [ruta, setRuta] = useState({});
    const [advertenciasImagenes, setAdvertenciasImagenes] = useState([]);
    const [imagenes, setImagenes] = useState([]);
    const [lastupdate, setLastupdate] = useState("");
    const [showAdvertencias, setShowAdvertencias] = useState([]);

    const [searchValue, setSearchValue] = useState();

    const [modal2Visible, setModal2Visible] = useState(false);

    const { id } = useParams(); //nombre del url a buscar
    let navigate = useNavigate ();


    const onSearch = (value) => setSearchValue(value);

    
    //method to search routes by name 
    const searchRoutes = (routes, search) => {
        //if search is empty, return all routes
        if (!search) {
            return routes;
        }
        else {
            return routes.filter(route => {
                return route.nombre.toLowerCase().includes(search.toLowerCase());
            });
        }
    }


    //get 10 random routes and set to rutasmostar
    const getRutasmostrar = (rutas,search) => {
        if(search){
            //perform search of the route by name
            let results = searchRoutes(rutas,search);
            return results;
        }else{
            let random = [];
            //check if the array has 10 elements or more
            if (rutas.length >= 10) {
                for (let i = 0; i < 10; i++) {
                    //randomlly pick a route and check if it is already in the array
                    let randomRoute = rutas[Math.floor(Math.random() * rutas.length)];
                    if (!random.includes(randomRoute)) {
                        random.push(randomRoute);
                    }
                }
                return random;
            } else {
                for (let i = 0; i < rutas.length-1; i++) {
                    //randomlly pick a route and check if it is already in the array
                    let randomRoute = rutas[Math.floor(Math.random() * rutas.length)];
                    if (!random.includes(randomRoute)) {
                        random.push(randomRoute);
                    }
                }
                return random;
            }
        }
    }
    //call api to get all routes
    const byurl = () => {
        try{
            axios.get(link_api + "/rutaindividual?url=/" + id) 
                .then(res => {
                    setRuta(res.data);
                })
        }catch(error){
            console.log(error);
        }
    }

    useEffect(() => {
    byurl();
        
    }, []);

    
    

    //populate images array with the images of the route
    useEffect(() => {
        if(ruta.imagenes){
            setImagenes(ruta.imagenes);
        }
    }, [ruta]);

    //establecer ultima actualizacion de la ruta
    useEffect(() => {
        ruta.updatedAt = new Date(ruta.updatedAt);
        setLastupdate(ruta.updatedAt.toLocaleString());
    }, [ruta]);

    //console.log(ruta)

    
    
    //populate advertenciasImagenes with each image of the route
    useEffect(() => {
        if(ruta.imagenes){
            let imagenAdvertencias = [];
            ruta.advertencias.forEach(advertencia => {
                imagenAdvertencias.push("/img/Íconos/PNG sin fondo/"+advertencia+".png");
            })
            setAdvertenciasImagenes(imagenAdvertencias);
        }
    }, [ruta]);

    //function to go to external link
    const goToLink = (link) => {
        window.open(link, "_blank");
    }


    return (
        <div style={{backgroundColor:"#fafaf9"}}>  
            <Header/>
            <br/><br/><br/>
{/* 
            {isXLdesktop && (
                <Anchor offsetTop={100} >
                    <Link href="#Compartir" title="Compartir" />
                    <Link href="#Basicos" title="Informacion basica" />
                    <Link href="#Llegar" title="Como llegar" />
                    <Link href="#Vereda" title="Como es la vereda"/>
                    <Link href="#Galeria" title="Galeria de fotos" />
                </Anchor>
            )}
*/}            
            <div className="contentRutaMain">
                <Row>
                    <Col span={24}>
                        <Row>
                            <Col flex={4}>
                                <div className="informacionRuta">
                                    <TopCard ruta={ruta} isMobile={isMobile}/>
                                    
                                    <InformacionBasica ruta={ruta} isMobile={isMobile}/>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <ComoLlegar ruta={ruta} isMobile={isMobile}/>           
                {/*Como Llegar*/}
                
                <ComoVereda ruta={ruta} isMobile={isMobile}/>
                {/*Como Es la Vereda*/}
                
            </div>
            <Footer/>
        </div>
    );
}

export default Ruta;