import { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import { Avatar, Card } from 'antd';
import { Tabs } from 'antd';
import { Col, Row } from 'antd';
import './nuestrosAliados.css'
import axios from 'axios';
import Container from 'react-bootstrap/Container';

const { Meta } = Card;
const { TabPane } = Tabs;

const link_api = process.env.REACT_APP_API_MONGO_URL;


const NuestrosAliados = ({isMobile}) => {
    const [mostrarCompanias, setMostrarCompanias] = useState([]);
    const [companias, setCompanias] = useState([]);

    useEffect(() => {
        try {
            axios.get(link_api + '/afiliados')
                .then(res => {
                    setCompanias(res.data);
                })
        } catch (err) {
            console.log(err);
        }
    }, [])
    


  return (
    <div className='nuestros-aliados'>
        <div className='nuestros-aliados-container'>
            <br/>
                    <div className="nuestros-aliados-titulo-container">
                        <h1 className="nuestros-aliados-texto-container">Nuestros Aliados</h1>
                        <h2 className="nuestros-aliados-texto-container-parrafo"> Agradecemos a las siguientes empresas, organizaciones y asociaciones por su apoyo incondicional al proyecto.</h2>
                    </div>

            <Row className='companias-container'>
                {companias.map(compania =>
                        <Col span={12} sm={8} md={6} lg={4}>
                            <Image className="foto-compania" src={compania.foto} />
                        </Col>
                )}
            </Row>
        </div>
    </div>
  )
}

export default NuestrosAliados