import {useState, useEffect} from 'react'
import {Image, Row, Col} from 'antd'

import './dificultad.css'

const DificultadHike = ({dificultad, setDificultad}) => {

  const [iconos, setIconos] = useState([{}]);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth <= 650) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
}

// Reactive window resize
useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize()
})


  useEffect(() => {
    let lista = [];
    for(let i = 1; i<=10; i++){
      lista.push({"icon" :"img/Íconos/PNG fondo blanco/dificultades/SVG/" + i + ".svg",
                    "rango" : (i-1)*200 + "-" + (i)*200,
                  "numero": i});
    }
    setIconos(lista);
  }, [])

  return (
    <div>
      <h1 className='titulo'>¿Qué dificultad física prefieres?</h1>
      { !isMobile &&
      <h2 className='subtitulo'>Un cerro puede tener poco desnivel pero ser muy técnico. Para prevenir accidentes, selecciona solo lo que sí sabes hacer.</h2>
      }
      <Row justify="space-evenly" gutter={[16,8]}>
      {iconos.map((e) =>{
        return(
          <Col span={6} md={2}>
            <div className='center-image'>
              <Image 
              className={(dificultad.includes(e.numero)) ? '' : 'gray'}
                preview={false}
                  src={e.icon} 
                  fluid 
                  alt={`Icono Dificultad`} 
                  onClick={() => {
                    if(dificultad.includes(e.numero)) setDificultad(dificultad.filter(ee => ee !== e.numero))
                    else setDificultad([...dificultad, ...[e.numero]])
                  }}
              />
            </div>
            <p className='rango-dificultades'>{e.rango}</p>

            <div className={(dificultad.includes(e.numero)) ? 'button-div checked' : 'button-div' } onClick={() => {
              if(dificultad.includes(e.numero)) setDificultad(dificultad.filter(ee => ee !== e.numero))
              else setDificultad([...dificultad, ...[e.numero]])
            }}>
              {(dificultad.includes(e.numero)) ? 
              (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>)
            :
              (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="black">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>)
            }
              

            </div>

          </Col>)
        })}
      </Row>
    </div>
  )
}

export default DificultadHike