import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Image from 'react-bootstrap/Image';
import { useNavigate,useLocation } from "react-router-dom";

import './Header.css';

const drawerWidth = 240;

const navItems = ['Rutas',/* 'Tips',*/ 'Nosotros'/*,'Proyectos'*/, 'Donar']; //Opciones Menu
// const navItems = ['Rutas', 'Tips', 'Nosotros','Planea','Proyectos']; //Opciones Menu


function Header(props){

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  let navigate = useNavigate ();
  const { pathname } = useLocation();

  

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function parseResult(item){
    //lowercase
    let itemLower = item.toLowerCase();
    //replace spaces
    let itemReplace = itemLower.replace(/ /g, "-");

    //replace accents
    let itemAccents = itemReplace.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    
    //return
    return itemAccents;
  }

  

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} className="drawerMobile">
      <Typography variant="h6" sx={{ my: 2 }}>
          <Image
                src="/img/Logos/Letras Largo Blanco.png"
                fluid
                alt="Logo LCC"
                className='logoImgMobile'
                onClick={() => navigate("/")}
              />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} >
              <ListItemText primary={
                <Typography type="body2">
                  
                  {pathname.includes(parseResult(item.substring(0,4))) ? 
                    (<p className="options-mobile-drawer-true">{item}</p>) 
                  : 
                    (<p className="options-mobile-drawer">{item}</p>)
                  }


                </Typography>
              } onClick={() => navigate("/"+parseResult(((item === 'Rutas') ? 'Ruta' : item) ))}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav" className='navbar'>
        <Toolbar className='innerNavbar'>
          
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <Image
                src="/img/Logos/Letras Largo Blanco.png"
                fluid
                alt="Logo LCC"
                className='logoImg'
                onClick={() => {navigate('/')}}
              />
          </Typography>
          <Typography variant="h6" sx={{ display: { xs: 'block', sm: 'none' } }}>
            <Image
                  src="/img/Logos/Letras Largo Blanco.png"
                  fluid
                  alt="Logo LCC"
                  className='logoImgMobile'
                  onClick={() => navigate("/")}
                />
        </Typography>

          
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            {navItems.map((item) => (
              <Button key={item} className="buttonNav" style={{fontFamily: 'Bebas Neue,cursive',paddingLeft:"1rem",paddingRight:"1rem"}} onClick={() => navigate("/"+parseResult(((item === 'Rutas') ? 'Ruta' : item) ))}>
                {/*make text whitesmoke if in current route */}
                {pathname.includes(parseResult(item.substring(0,4))) ? <span style={{color:"whitesmoke"}}>{item}</span> : item}
              </Button>
            ))}
          </Box>

          <div className="right-aling">
            
          <IconButton 
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'block' ,  md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <SwipeableDrawer
          anchor='right'
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block',  md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </SwipeableDrawer>
      </Box>

    </Box>
  );
};

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
