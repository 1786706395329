import { Col, Row, Input, Tabs,  } from 'antd';
import Card from '@mui/material/Card';
import parse from 'html-react-parser';


import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HikingIcon from '@mui/icons-material/Hiking';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import { Anchor } from 'antd';
import { Typography } from 'antd';

import 'antd/dist/antd.less'; 

import './comoLlegar.css';

const { Title } = Typography;


const ComoLlegar = ({ruta, isMobile}) => {

  return (
    <div id="Llegar" className="comoLlegarContentt">
        <Row gutter={16}>
            <Col span={12} xs={24} md={12}>
                <h1 className="comoLlegarTitulo">¿Cómo llegar?</h1>
                <div className="informacionUbicacion">
                    <Row gutter={16} className="comoLlegarContent">
                        <Col span={2} xs={4} md={2}>
                            <p><FmdGoodIcon fontSize="large"/></p>
                        </Col>
                        <Col span={22} xs={18} md={22}>
                            <Title level={4} className="texto-descripcion-titulo">Ubicación:</Title>
                            <p className="texto-descripcion">{ruta.comoLlegar}</p>
                            
                        </Col>
                    </Row>
                    {/* 
                    <Row gutter={16} className="comoLlegarContent">
                        <Col span={2} xs={4} md={2}>
                            <p><DirectionsCarIcon fontSize="large"/></p>
                        </Col>
                        <Col span={22} xs={18} md={22}>
                            <Title level={4} className="texto-descripcion-titulo">Tipo de carro:</Title>
                            <p className="texto-descripcion">{ruta.tipoVehiculo}</p>
                        </Col>
                    </Row>*/}
                    <Row gutter={16} className="comoLlegarContent">
                        <Col span={2} xs={4} md={2}>
                            <p><LocalParkingIcon fontSize="large"/></p>
                        </Col>
                        <Col span={22} xs={18} md={22}>
                            <Title level={4} className="texto-descripcion-titulo">Estacionamiento:</Title>
                            <p className="texto-descripcion">{ruta.comoEstacionarse}</p>
                        </Col>
                    </Row>
                    <Row gutter={16} className="comoLlegarContent">
                        <Col span={2} xs={4} md={2}>
                            <p><AttachMoneyIcon fontSize="large"/></p>
                        </Col>
                        <Col span={22} xs={18} md={22}>
                            <Title level={4} className="texto-descripcion-titulo">Costos y Horario:</Title>
                            <p className="texto-descripcion">{ruta.costos}</p>
                        </Col>
                    </Row>
                    <Row gutter={16} className="comoLlegarContent">
                        <Col span={2} xs={4} md={2}>
                            <p><HikingIcon fontSize="large"/></p>
                        </Col>
                        <Col span={22} xs={18} md={22}>
                            <Title level={4} className="texto-descripcion-titulo">Equipo Adicional:</Title>
                            <p className="texto-descripcion">{ruta.equipoAdicional}</p>
                        </Col>
                    </Row>
                    <Row gutter={16} className="comoLlegarContent">
                        <Col span={2} xs={4} md={2}>
                            <p><ForkLeftIcon fontSize="large"/></p>
                        </Col>
                        <Col span={22} xs={18} md={22}>
                            <Title level={4} className="texto-descripcion-titulo">Trazo De Ruta:</Title>
                            <p className="texto-descripcion">{ruta.trazoDeLaRuta}</p>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col span={12} xs={24} md={12}>
                <Card id="wikiloc">
                    {(!isMobile && ruta.mapaHTMLWikiloc) ? parse(ruta.mapaHTMLWikiloc) : (<></>)}
                    
                </Card>
            </Col>
        </Row>
    </div>
                
  )
}

export default ComoLlegar