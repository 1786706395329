import './banners.css'
import { Col, Image } from 'antd';
import { useNavigate } from "react-router-dom";

const Banners = () => {
    const navigate = useNavigate ();
  return (
    <>
        <Col span={16}>
          {/* 
              <div className="planea-dificultad-banner">
                <Image
                className="imagen-banners"
                preview={false}
                onClick={(e) => navigate("/dificultades")}
                        width={240}
                        src="https://cdnlcc.ga/clasificacion.webp"
                      />
                <Image
                className="imagen-banners"
                onClick={(e) => navigate("/planea")}
                preview={false}
                        width={240}
                        src="https://cdnlcc.ga/ayuda.webp"
                      />
              </div>*/}
            </Col>
        </>
  )
}

export default Banners