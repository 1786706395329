import { useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Image from 'react-bootstrap/Image';
import { useNavigate } from "react-router-dom";
import Dificultad from "../common/dificultad";

import { Col, Row } from 'antd';
import './RouteCard.css';

const RouteCard = (props) => {
    const { route } = props;
    let navigate = useNavigate ();

    const [tiempo, setTiempo] = useState('');
    useEffect(() => {
        let tiempoString = "";

        if(route.minutos && route.horas){
            //convert minutes to hours
            let minutesToHours = route.minutos / 60;

            tiempoString = (route.horas+minutesToHours).toFixed(1) + " hrs";

        }
        else if(route.horas){
            tiempoString = route.horas + " hrs";
        }
        else if(route.minutos){
            tiempoString = route.minutos + " min";
        }
        setTiempo(tiempoString);
    }, [])
    
    
    
    function visitRoute() {
        //console.log(route._id+" "+route.nombre);
        navigate("/rutas"+route.url);
    }

    
    return (
        <Card>
                <CardActionArea onClick={() => { visitRoute() }}>
                <Row className='card-size2'>
                    {/* 80% span */}
                    <Col span={16}>
                        <CardContent>
                            <Row>

                                <Col span={18}>
                                    <h5 className="titulo-text-card" title={route.nombre}>{route.nombre}</h5>

                                </Col>
                                <Col span={6}>
                                    <Dificultad fisico={route.nivelFisico} tecnico={route.nivelTecnico} solar={route.nivelSolar} />
                                </Col>

                            </Row>
                            <Typography variant="body2" color="text.secondary" title={route.area}>
                                <h5 className="area-text-card">{route.area}</h5>
                            </Typography>
                            <Row style={{ textAlign: "center", paddingTop: "0.5rem" }}>
                                <Col span={8} title="Desnivel">
                                    <Row>
                                        <Image
                                            src="./img/Íconos/altitud.png"
                                            fluid
                                            alt="Logo LCC"
                                            className='icon'
                                        />
                                        <p className="datos-card">{route.desnivel}m</p>
                                    </Row>
                                </Col>
                                <Col span={8} title="Distancia">
                                    <Row>
                                        <Image
                                            src="./img/Íconos/distancia.png"
                                            fluid
                                            alt="Logo LCC"
                                            className='icon'
                                        />
                                        <p className="datos-card">{route.distancia}km</p>
                                    </Row>
                                </Col>
                                <Col span={8} title="Tiempo">
                                    <Row>
                                        <Image
                                            src="./img/Íconos/tiempo.png"
                                            fluid
                                            alt="Logo LCC"
                                            className='icon'
                                        />
                                        <p className="datos-card">{tiempo}</p>
                                    </Row>

                                </Col>

                            </Row>

                        </CardContent>
                    </Col>
                    <Col span={8}>
                        <CardMedia
                            component="img"
                            height="150"
                            image={`https://imagedelivery.net/Ld9aiKCsB0YY5djFoM113Q/${route.imagenPortada}/planea`}
                            alt={`route`}
                            loading="lazy"
                        />
                    </Col>
                </Row>
            </CardActionArea>
        </Card>
    );
}

export default RouteCard;