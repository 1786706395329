import React from 'react'
import {useState} from 'react'
import { useNavigate } from "react-router-dom";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { Button, CardActionArea, CardActions } from '@mui/material';
import Image from 'react-bootstrap/Image';


import { Col, Row } from 'antd';
import './tipCard.css';

const TipCard = (props) => {
    const data = props.data;

    let navigate = useNavigate ();

    function visitRoute() {
        navigate("/tips"+data.url);
    }

  return (
    <div>
      <Card sx={{ maxHeight: 372 }}>
            <CardActionArea onClick={() => {visitRoute()}}>
                <div style={{ position: "relative" }}>
                    <CardMedia
                        component="img"
                        height="224"
                        image={props.data.imagenPortada}
                        alt="green iguana"
                    />
                    <div className="texto" >
                        <Typography variant="h5" component="div" color="common.white" style={{left: "10px", bottom: "4px", position: "absolute"}}>
                            {props.data.titulo}
                        </Typography>
                    </div>
                </div>
                
                {/* <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {props.data.titulo}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">{props.data.descripcionBasica}</Typography>
                </CardContent> */}
            </CardActionArea>
        </Card>
      </div>
  )
}

export default TipCard