import {useState} from "react";
import { Col, Row,  } from 'antd';
import Card from '@mui/material/Card';
import Dificultad from "../../common/dificultad";

import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MapIcon from '@mui/icons-material/Map';
import IosShareIcon from '@mui/icons-material/IosShare';

import { Typography } from 'antd';

import Carousell from "./carousel/carousel";

import 'antd/dist/antd.less'; // or 'antd/dist/antd.less'

import './topCard.css';


const TopCard = ({ruta, isMobile}) => {

    const goToLink = (link) => {
        window.open(link, "_blank");
    }

    const handleOnClick = () => {
        if (navigator.share) {
          navigator
            .share({
              title: `${ruta.nombre}`,
              text: `${ruta.seo}`,
              url: document.location.href,
            })
            .then(() => {
              console.log('Successfully shared');
            })
            .catch(error => {
              console.error('Something went wrong sharing the blog', error);
            });
        }
      };


  return (
    <>  
        <Carousell ruta={ruta} isMobile={isMobile}/>
        <Card>
            <Row className="height">
                <Col span={6}>
                    <div className="submenuRuta" onClick={() =>{goToLink(ruta.mapaGoogle)}}>
                        <DirectionsCarIcon/>
                        <p className="submenuRutaText">¿Cómo llegar?</p>
                    </div>
                </Col>
                <Col span={6}>
                    <div className="submenuRuta" onClick={() =>{goToLink(ruta.linkWikiloc)}}>
                        <MapIcon/>
                        <p className="submenuRutaText">Mapa</p>
                    </div>
                </Col>
                <Col span={6}>
                    <div className="submenuRuta" onClick={() => handleOnClick()}>
                        <IosShareIcon/>
                        <p className="submenuRutaText">Compartir</p>
                    </div>
                </Col>
                <Col span={6}>
                <div className="submenuRutaDificultad">
                    <div className="submenuDificultad">
                        <Dificultad fisico={ruta.nivelFisico} tecnico={ruta.nivelTecnico} solar={ruta.nivelSolar}/>
                           
                    </div>
                </div>
                </Col>
            </Row>
        </Card>
    </>
  )
}

export default TopCard