import React from "react";
import { Card } from 'antd';

import { useEffect, useState } from "react";

import 'antd/dist/antd.min.css';
import './misionVision.css';
import { Carousel, Divider } from 'antd';
import { Layout } from 'antd';
import { Col, Row } from 'antd';
import { Image } from 'antd';

const { Content } = Layout;
const { Meta } = Card;
const backgroundImageRutaUrl = "https://imagedelivery.net/Ld9aiKCsB0YY5djFoM113Q/d3d84dfb-c25c-465a-679c-f56af28ab000/public";


const MissionVision = () => {
  return (
    <>
        <Row justify="space-evenly" className="mision-vision-container">
            <Col span={8} xs={24} sm={24} md={6} lg={6}>
              <div className="mision-vision-icono">
                <Image 
                    src='img\Íconos\Mision\Misión.webp' 
                    fluid 
                    preview={false}
                    alt={`Dificultad fisica`} 
                />
              </div>
              <h2 className="mision-vision-title-1">Misión</h2>
              <p className="mision-vision-text-1">Hacer el senderismo más accesible a través de educación, señalética y mantenimiento de veredas.</p>
            </Col>
            <Col span={8} xs={24} sm={24} md={6} lg={6}>
              <div className="mision-vision-icono">
                <Image 
                    src='img\Íconos\Mision\Visión.webp' 
                    fluid 
                    preview={false}
                    alt={`Dificultad fisica`} 
                />
              </div>
              <h2 className="mision-vision-title-1">Visión</h2>
              <p className="mision-vision-text-1">Proteger nuestras áreas naturales para asegurar un medio ambiente sano y sostenible.</p>
            </Col>
            <Col span={8} xs={24} sm={24} md={6} lg={6}>
              <div className="mision-vision-icono">
                <Image 
                    src='img\Íconos\Mision\Propósito.webp' 
                    fluid 
                    preview={false}
                    alt={`Dificultad fisica`} 
                />
              </div>
              <h2 className="mision-vision-title-1">Propósito</h2>
              <p className="mision-vision-text-1">Crear una comunidad de senderistas responsables estableciendo un valor ecoturístico a nuestras áreas naturales.</p>
            </Col>
          </Row>
    </>
  )
}

export default MissionVision