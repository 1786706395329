import { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import { Col, Row } from 'antd';
import './queHacemos.css'
import Container from 'react-bootstrap/Container';


const info = [
    {
        "nombre": "Rutas de Senderismo",
        "descripcion": "Implementamos señalamientos y medidas de seguridad en senderos populares. Cuidamos y mantenemos esta red de rutas para prevenir desgaste ecológico y minimizar accidentes y/o extrávios en la montaña.",
        "imagen": "https://firebasestorage.googleapis.com/v0/b/imageneswebsite-a5022.appspot.com/o/mision%2FSe%C3%B1alizaci%C3%B3n-6ab25ea0-4755-42d5-b1aa-427fe33b94b9?alt=media&token=821c1a8e-5107-4b5a-a0ef-a0f0d194fe96"
    },
    {
        "nombre": "Educación Ecológica",
        "descripcion": "Colaboramos con expertos en diferentes áreas para ofrecer cursos y talleres sobre el cuidado de nuestro medio ambiente y nuestra responsabilidad ecológica como ciudadanos.",
        "imagen": "https://firebasestorage.googleapis.com/v0/b/imageneswebsite-a5022.appspot.com/o/mision%2FNaturaleza-1e4368eb-4d75-40a5-a64d-bef8c8149e6a?alt=media&token=21e115f9-1869-494f-84b4-b857c4a5f321"
    },
    {
        "nombre": "Justicia Ambiental",
        "descripcion": "Luchamos para preservar nuestras áreas verdes de cualquier daño o desarrollo ilícito usando los medios que ofrece la ley y con la concientización de la comunidad.",
        "imagen": "https://firebasestorage.googleapis.com/v0/b/imageneswebsite-a5022.appspot.com/o/mision%2FLeyes-6db6d110-2fc7-44fd-bce1-e664ff5247ca?alt=media&token=96ea96f7-96ce-448a-87d2-801a37ea0355"
    },
    {
        "nombre": "Seguridad en el Cerro",
        "descripcion": "Concientizamos a nuestra comunidad sobre la seguridad en la montaña. También, junto con empresas certificadas, organizamos cursos de primeros auxilios y rescate en zonas agrestes. ",
        "imagen": "https://firebasestorage.googleapis.com/v0/b/imageneswebsite-a5022.appspot.com/o/mision%2FPrecauci%C3%B3n-be438586-ede0-411d-88a2-77cff66500e8?alt=media&token=67c0e29b-30a8-42cf-b07a-da791be5a93e"
    },
    {
        "nombre": "Eventos Sociales",
        "descripcion": "Fomentamos una comunidad practicante de deportes en la montaña a través de eventos sociales con diferentes asociaciones y empresas cuyos públicos comparten este interés.",
        "imagen": "https://firebasestorage.googleapis.com/v0/b/imageneswebsite-a5022.appspot.com/o/mision%2FCalendario-50bec375-6f00-4ca2-83cc-92693e45f43e?alt=media&token=e5bea8f7-7250-42eb-a1dc-be09ea9b3011"
    },
    {
        "nombre": "Involucramiento Comunitario",
        "descripcion": "Trabajamos con comunidades urbanas y rurales en las que senderos cruzan propiedad privada para fortalecer la relación entre deportistas y propietarios.",
        "imagen": "https://firebasestorage.googleapis.com/v0/b/imageneswebsite-a5022.appspot.com/o/mision%2Fcomunidad-e36bee06-9c44-4ab2-8bc9-ec0c4608fa7b?alt=media&token=c8f4810c-6bc5-4915-8411-063c45773a98"
    }
]


const QueHacemos = ({isMobile}) => {
    
  return (
    <div className="que-hacemos">
        <div className="que-hacemos-container">
        <br/>
                <div className="que-hacemos-titulo-container">
                    <h1 className="que-hacemos-texto-container">¿Qué hacemos?</h1>
                    <p className="que-hacemos-parrafo-container">Si pudiéramos resumir nuestros proyectos en 6 categorías serían las siguientes:</p>
                </div>
                
                <Row gutter={[24, 24]}>
                    {info.map(element =>
                        <Col span={24} sm={12} md={8}>
                            <Row gutter={[16, 16]}>
                                <Col span={6} md={8}>
                                    <Image
                                        src={element.imagen}
                                        width={'100%'}
                                    />
                                </Col>
                                {isMobile && (
                                    <Col span={18}>
                                        <h1 className="titulo-descripcion">{element.nombre}</h1>
                                    </Col>
                                )}
                                <Col span={24} md={16}>
                                    {!isMobile && (<h1 className="titulo-descripcion">{element.nombre}</h1>)}
                                    <p className="texto-descripcion">{element.descripcion}</p>
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
        </div>
    </div>
    
  )
}

export default QueHacemos