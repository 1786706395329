import Image from 'react-bootstrap/esm/Image';

const dicc = {1 : 'I', 2 : 'II', 3 : 'III'}

const Dificultad = ({fisico, tecnico, solar}) => {
    solar = dicc[solar];
    tecnico = tecnico+1;
    
  return (
        <Image 
            src={`/img/Íconos/SVG/${fisico}/${solar}/${tecnico}.svg`} 
            fluid 
            alt={`Dificultad fisica ${fisico} dificultad solar ${solar} dificultad tecnica ${tecnico}`} 
            loading='lazy'
            title={`Dificultad fisica ${fisico} dificultad solar ${solar} dificultad tecnica ${tecnico}`} 
        />
  )
}

export default Dificultad