import { useState, useCallback } from 'react';
import { Carousel,Image  } from 'antd';

import 'antd/dist/antd.less'; // or 'antd/dist/antd.less'
import './carousel.css';

const Carousell = ({ruta, isMobile}) => {


  return (
    <>
      <Carousel dotPosition={'top'} autoplay>
        <div className='prueba'>
          {isMobile ? (<Image
                    src={`https://imagedelivery.net/Ld9aiKCsB0YY5djFoM113Q/${ruta.imagenPortada}/public`}
                    alt={`Foto de ${ruta.nombre}`}
                    type="image/webp"
                    className="imagen-carousel"
                />) : (<img
                        src={`https://imagedelivery.net/Ld9aiKCsB0YY5djFoM113Q/${ruta.imagenPortada}/public`}
                        alt={`Foto de ${ruta.nombre}`}
                        loading="lazy"
                        type="image/webp"
                        className="imagen-carousel"
                    />)}
                
                    
        </div>
        {ruta.imagenes !== undefined && ruta.imagenes.map((e,key) => {
            return(
                <div key={e}>
                  {isMobile ? 
                  (<Image
                    src={`https://imagedelivery.net/Ld9aiKCsB0YY5djFoM113Q/${e.file}/public`}
                    alt={`Foto de ${e.nombre}`}
                    type="image/webp"
                    className="imagen-carousel"
                  />) 
                    : 
                  (<img
                    src={`https://imagedelivery.net/Ld9aiKCsB0YY5djFoM113Q/${e.file}/public`}
                    alt={`Foto de ${e.titulo}`}
                    loading="lazy"
                    type="image/webp"
                    className="imagen-carousel"
                  />)}
                </div>
            )
        })}
      </Carousel>
    </>
  )
}

export default Carousell