import {useState, useEffect} from 'react'
import {Row, Col, Button} from 'antd'
import axios from "axios"

import TipoHike from './Forms/TipoHike'
import DificultadHike from './Forms/DificultadHike'
import TecnicoHike from './Forms/TecnicoHike'
import TiempoHike from './Forms/TiempoHike'
import AltitudHike from './Forms/AltitudHike'
import SolHike from './Forms/SolHike'
import VehiculoHike from './Forms/VehiculoHike'
import ResultadosHike from './Forms/ResultadosHike'

import Cards from './Cards'

import Header from '../common/Header'
import Footer from '../common/Footer'

import './styles.css';

const link_api = "https://us-east-1.aws.data.mongodb-api.com/app/website-zmlpn/endpoint";
const Planea3 = () => {

    const [paso, setPaso] = useState(1);    
    const [rutas, setRutas] = useState([]);
    const [showRutas, setShowRutas] = useState([]);

    useEffect(() => {
        try {
            axios.get(link_api + "/rutas")
              .then(res => {
                setRutas(res.data);
                setShowRutas(res.data);
              })
          } catch (error) {
            console.log(error);
          }
    }, [])
    
    

    const [tipoHike, setTipoHike] = useState(['cumbre', 'recorrido']);
    const [dificultad, setDificultad] = useState([1,2,3,4,5]);
    const [tecnico, setTecnico] = useState([1,2,3,4,5]);
    const [tiempo, setTiempo] = useState(false);
    const [altitud, setAltitud] = useState(['menos']);
    const [sol, setSol] = useState([1,2]);
    const [vehiculo, setVehiculo] = useState(true);

    useEffect(() => {
        console.log(tipoHike, dificultad, tecnico, tiempo, altitud, sol, tipoHike, vehiculo);
        let lista = rutas;

        lista = (lista.filter(route => { //filtrar por tipo de hike
            const tipos = route.tipoRecorrido;
            for(let i = 0; i<tipos.length; i++){
                if(tipos[i].toLowerCase() === 'mirador' || tipos[i].toLowerCase() === 'cañon') tipos[i] = 'recorrido'; 
                //esto es porque en la base de datos no tenemos recorrido entonces tomamos canon y mirador como recorrido

                if(tipoHike.includes(tipos[i].toLowerCase())) return true;
            }
            return false;
        }));

        lista = (lista.filter(route => dificultad.includes(route.nivelFisico)));
        lista = (lista.filter(route => tecnico.includes(route.nivelTecnico)));
        lista = (lista.filter(route => (route.horas < (tiempo ? 999 : 4))));
        
        lista = (lista.filter(route => { //filtrar por altitud de hike
            if(altitud.includes('menos') && altitud.includes('mas')){
                return true;
            }else if (altitud.includes('menos')){
                return (route.altutudmsnm < 2500);
            }else if (altitud.includes('mas')){
                return (route.altutudmsnm >= 2500);   
            }else{
                return false;
            }
        }));

        lista = lista.filter(route => sol.includes(route.nivelSolar));

        lista = lista.filter(route => (vehiculo || route.tipoVehiculo  === 'Carro'));
        

        console.log(lista);

      }, [tipoHike, dificultad, tecnico, tiempo, altitud, sol, vehiculo])


  return (
    <div style={{backgroundColor:"#2b2b2b"}}>
        <Header />

        <br/><br/><br/>

        <div className='main-content'>
            <div className='cuadro'>
                <div  className='forms-content'>
                    <Row>
                        <Col span={24}>
                        {
                            {
                            1: <TipoHike tipoHike={tipoHike} setTipoHike={setTipoHike}/>,
                            2: <DificultadHike dificultad={dificultad} setDificultad={setDificultad}/>,
                            3: <TecnicoHike tecnico={tecnico} setTecnico={setTecnico}/>,
                            4: <TiempoHike tiempo={tiempo} setTiempo={setTiempo} />,
                            5: <AltitudHike altitud={altitud} setAltitud={setAltitud} />,
                            6: <SolHike sol={sol} setSol={setSol}/>,
                            7: <VehiculoHike vehiculo={vehiculo} setVehiculo={setVehiculo}/>,
                            8: <ResultadosHike />,
                            }[paso]
                        }
                        
                        </Col>
                        {/* 
                        <Col span={6}>
                            <Cards />
                        </Col>
                        */}
                    </Row>
                </div>
                <Button className='asdff' onClick={(e) => {setPaso((paso-1 <= 0 ? 1 : paso-1))}}>Izquierda</Button>
                <Button className='asdf' onClick={(e) => {setPaso((paso+1 >= 8 ? 8 : paso+1))}}>Derecha</Button>
                        
                   
            </div>
        </div>
        

        <br/>
        <Footer />  
    </div>
  )
}

export default Planea3