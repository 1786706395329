import { useEffect, useState } from "react";

import 'antd/dist/antd.min.css'; 
import './popovers.css';

import { Select, Col, Row, Radio,Space, Popover, Tooltip   } from 'antd';
import Slider from '@mui/material/Slider';

const { Option } = Select;

const Popovers = ({setShowRoutes, routes, orden, setOrden}) => {
  const [isMobile, setIsMobile] = useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize();
  });
  
  const [ordenBool, setOrdenBool] = useState(false);

  const [optionMunicipios, setOptionMunicipios] = useState([]);
  const [selectedMunicipios, setSelectedMunicipios] = useState([]);
  const [municipio, setMunicipio] = useState([]);

  const [optionAreas, setOptionAreas] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [area, setArea] = useState([]);

  const [optionRecorridos, setOptionRecorridos] = useState([]);
  const [selectedRecorridos, setSelectedRecorridos] = useState([]);
  const [recorrido, setRecorrido] = useState([]);

  const [distancia, setDistancia] = useState([0, 40]);
  const [desnivel, setDesnivel] = useState([0, 3000]);
  const [altutudmsnm, setAltitudmsnm] = useState([0, 4000])
  const [tiempo, setTiempo] = useState([0, 20]);
  const [nivelTecnico, setNivelTecnico] = useState([0, 6]);
  const [nivelFisico, setNivelFisico] = useState([1, 10]);
  const [nivelSolar, setNivelSolar] = useState([1, 3]);

  const [textParaFlechaPill, setTextParaFlechaPill] = useState("");

  const nivelSolarToString = (value) => {
    return ((value === 1 ? ('I') : (value === 2) ? ('II') : ('III')));
  }

  const handleChangeMunicipios = (event, newValue) => {
    setSelectedMunicipios(newValue);
    setMunicipio(newValue.map((e) => e.children));
  }

  const handleChangeArea = (event, newValue) => {
    setSelectedAreas(newValue);
    setArea(newValue.map((e)=>e.children));
  }

  const handleChangeRecorrido = (event, newValue) => {
    setSelectedRecorridos(newValue);
    setRecorrido(newValue.map((e) => e.children));
  }

  const inicializarSelectoresPorAreas = () => {
    let uniqueMunicipios = ["Cualquier Municipio", ...new Set(routes.map(route => route.municipio))];

    let options1 = [];
    let simpleOptions1 = [];
    uniqueMunicipios.forEach((municipio, index) => {
      options1.push(<Option key={index++}>{municipio}</Option>)
      simpleOptions1.push(municipio);
    });
    setSelectedMunicipios([options1[0]]);
    setOptionMunicipios(options1);
    setMunicipio(simpleOptions1);

    let uniqueAreas = ["Cualquier Area", ...new Set(routes.map(route => route.area))];
    //uniqueAreas.add("Cualquier Area")
    let options2 = [];
    let simpleOptions2 = [];
    uniqueAreas.forEach((area, index) => {
      simpleOptions2.push(area);
      options2.push(<Option key={index++}>{area}</Option>)
    });
    //console.log(options);
    setSelectedAreas([options2[0]]);
    setOptionAreas(options2);
    setArea(simpleOptions2);

    //select unique types of recorridos
    let tiposRecorridos = new Set(['Cualquier Tipo']);

    routes.forEach(route => {
      route.tipoRecorrido.forEach(element => {
        tiposRecorridos.add(element.toUpperCase());
      });
    });
    
    let options3 = [];
    let simpleOptions3 = [];
    let index3 = 0;
    tiposRecorridos.forEach((area) => {
      simpleOptions3.push(area);
      options3.push(<Option key={index3++}>{area}</Option>)
    });
    
    setSelectedRecorridos([options3[0]]);
    setOptionRecorridos(options3);
    setRecorrido(simpleOptions3);
  }

  useEffect(() => {
    inicializarSelectoresPorAreas();
  }, [routes]);

  useEffect(() => {
    let temp = routes;

    temp = (temp.filter(route => (route.nivelTecnico >= nivelTecnico[0] && route.nivelTecnico <= nivelTecnico[1])));
    temp = (temp.filter(route => (route.distancia >= distancia[0] && route.distancia <= distancia[1])));
    temp = (temp.filter(route => (route.desnivel >= desnivel[0] && route.desnivel <= desnivel[1])));
    temp = (temp.filter(route => (route.altutudmsnm >= altutudmsnm[0] && route.altutudmsnm <= altutudmsnm[1])));
    temp = (temp.filter(route => (route.horas>= tiempo[0] && route.horas <= tiempo[1])));
    temp = (temp.filter(route => (route.nivelSolar>= nivelSolar[0] && route.nivelSolar <= nivelSolar[1])));
    temp = (temp.filter(route => (route.nivelFisico>= nivelFisico[0] && route.nivelFisico <= nivelFisico[1])));
    
    if(!area.includes('Cualquier Area') && area.length !== 0)
      temp = (temp.filter(route => (area.includes(route.area))));
      
      
    if(!municipio.includes('Cualquier Municipio') && municipio.length !== 0)
      temp = (temp.filter(route => (municipio.includes(route.municipio))))

    //tipo de hike
    if(!recorrido.includes('Cualquier Tipo') && recorrido.length !== 0){
      let set = new Set();
      recorrido.forEach(element => {
        set.add(element.toLowerCase());
      });
      console.log(set);
      temp = (temp.filter(route => {
        for(let i = 0; i<route.tipoRecorrido.length; i++){
          console.log(route.tipoRecorrido[i]);
          if (set.has(route.tipoRecorrido[i])) return true;
        }
        return false;
      }));
    }
    switch(orden){
      case "desnivel":
        temp = (temp.sort((a, b) => {return (a.desnivel < b.desnivel) ? -1 : 1}));
        if(ordenBool) temp = (temp.sort((a, b) => {return (a.desnivel > b.desnivel) ? -1 : 1}));
        break;

      case "distancia":
        temp = (temp.sort((a, b) => {return (a.distancia < b.distancia) ? -1 : 1}));
        if(ordenBool) temp = (temp.sort((a, b) => {return (a.distancia > b.distancia) ? -1 : 1}));
        break;

      case "horas":
        temp = (temp.sort((a, b) => {return ((a.horas*60 + a.minutos) < (b.horas*60 + b.minutos)) ? -1 : 1}));
        if(ordenBool) temp = (temp.sort((a, b) => {return ((a.horas*60 + a.minutos) > (b.horas*60 + b.minutos)) ? -1 : 1}));
        break;

      case "dificultad fisica":
        temp = (temp.sort((a, b) => {return (a.nivelFisico < b.nivelFisico) ? -1 : 1}));
        if(ordenBool) temp = (temp.sort((a, b) => {return (a.nivelFisico > b.nivelFisico) ? -1 : 1}));
        break;

      case "dificultad tecnica":
        temp = (temp.sort((a, b) => {return (a.nivelTecnico < b.nivelTecnico) ? -1 : 1}));
        if(ordenBool) temp = (temp.sort((a, b) => {return (a.nivelTecnico > b.nivelTecnico) ? -1 : 1}));
        break;

      case "dificultad solar":
        temp = (temp.sort((a, b) => {return (a.nivelSolar < b.nivelSolar) ? -1 : 1}));
        if(ordenBool) temp = (temp.sort((a, b) => {return (a.nivelSolar > b.nivelSolar) ? -1 : 1}));
        break;  

      default:

    }
    setShowRoutes(temp);
  }, [nivelTecnico, distancia, desnivel, altutudmsnm, tiempo, nivelSolar, nivelFisico, municipio, area, recorrido, orden, ordenBool])
  


  return (
    <>
    <Row gutter={[16,16]} className="popover-margin-top">
        <Col span = {4} xs={12} sm={8} lg={4} className="popover-col-center">
          <Popover 
            placement={(isMobile ? ("bottomRight") : ("bottom"))}
            trigger="click"
            onVisibleChange={() => setTextParaFlechaPill((textParaFlechaPill === "kilometros") ? (" ") : ("kilometros"))}
            content={
            <div className="popover-content" >
                <div className="popover-container">
                <span className="popover-content-title-left">{distancia[0]}km</span>
                <span className="popover-content-title-right">{distancia[1]}km</span>
              <Slider
                className="custom-slider"
                getAriaLabel={() => 'Kilometros'}
                value={distancia}
                step={1} min={0} max={40}
                onChange={(event, newValue) => setDistancia(newValue)}
              /></div>
            </div>}>
            <div className={[((textParaFlechaPill === "kilometros") ? "popover-individual-true" : "popover-individual-false"), ((distancia[0] !== 0 || distancia[1] !== 40) && "popover-activated")].join(' ')}>
              <div className="popover-div">
                <Row>
                  <Col span={22}>
                  <p className="popover-text">{(distancia[0] === 0 && distancia[1] === 40) ? ("Distancia") : (distancia[0] + "km - " + distancia[1] + "km" )} </p> 
                  </Col>
                  <Col span={1}>
                  <div className="popover-flecha-icon"> {(textParaFlechaPill === "kilometros" ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="svg-arrow">
                  <path  stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
                
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="svg-arrow-rotated">
                    <path  stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                  </svg>
                  ))}</div>
                  </Col>
                </Row>
              </div>
            </div>
          </Popover>
        </Col>


        
        {/* DESNIVEL */}
        <Col span = {4} xs={12} sm={8} lg={4} className="popover-col-center">
          <Popover 
            placement={(isMobile ? ("bottomRight") : ("bottom"))}
            trigger="click"
            onVisibleChange={() => setTextParaFlechaPill((textParaFlechaPill === "desnivel") ? (" ") : ("desnivel"))}
            content={
            <div className="popover-content" >
                <div className="popover-container">
                <span className="popover-content-title-left">{desnivel[0]}m</span>
                <span className="popover-content-title-right">{desnivel[1]}m</span>
              <Slider
                className="custom-slider"
                getAriaLabel={() => 'Desnivel'}
                value={desnivel}
                step={100} min={0} max={3000}
                onChange={(event, newValue) => setDesnivel(newValue)}
              /></div>
            </div>}>
            <div className={[((textParaFlechaPill === "desnivel") ? "popover-individual-true" : "popover-individual-false"), ((desnivel[0] !== 0 || desnivel[1] !== 3000) && "popover-activated")].join(' ')}>
              <div className="popover-div">
                <Row>
                  <Col span={22}>
                  <p className="popover-text">{(desnivel[0] === 0 && desnivel[1] === 3000) ? ("Desnivel") : (desnivel[0] + "m - " + desnivel[1] + "m" )} </p> 
                  </Col>
                  <Col span={1}>
                  <div className="popover-flecha-icon"> {(textParaFlechaPill === "desnivel" ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="svg-arrow">
                  <path stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
                
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="svg-arrow-rotated">
                    <path  stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                  </svg>
                  ))}</div>
                  </Col>
                </Row>
              </div>
            </div>
          </Popover>
        </Col>

        {/* TIEMPO */} 
        <Col span = {4} xs={12} sm={8} lg={4} className="popover-col-center">
          <Popover 
            placement={(isMobile ? ("bottomRight") : ("bottom"))}
            trigger="click"
            onVisibleChange={() => setTextParaFlechaPill((textParaFlechaPill === "tiempo") ? (" ") : ("tiempo"))}
            content={
            <div className="popover-content" >
                <div className="popover-container">
                <span className="popover-content-title-left">{tiempo[0]}h</span>
                <span className="popover-content-title-right">{tiempo[1]}h</span>
              <Slider
                className="custom-slider"
                getAriaLabel={() => 'Tiempo'}
                value={tiempo}
                step={1} min={0} max={20}
                onChange={(event, newValue) => setTiempo(newValue)}
              /></div>
            </div>}>
            <div className={[((textParaFlechaPill === "tiempo") ? "popover-individual-true" : "popover-individual-false"), ((tiempo[0] !== 0 || tiempo[1] !== 20) && "popover-activated")].join(' ')}>
              <div className="popover-div">
                <Row>
                  <Col span={22}>
                  <p className="popover-text">{(tiempo[0] === 0 && tiempo[1] === 20) ? ("Tiempo") : (tiempo[0] + "h - " + tiempo[1] + "h" )} </p> 
                  </Col>
                  <Col span={1}>
                  <div className="popover-flecha-icon"> {(textParaFlechaPill === "tiempo" ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="svg-arrow">
                  <path  stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
                
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="svg-arrow-rotated">
                    <path  stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                  </svg>
                  ))}</div>
                  </Col>
                </Row>
              </div>
            </div>
          </Popover>
        </Col>

        {/* DIFICULTADES */} 
        <Col span = {4} xs={12} sm={8} lg={4} className="popover-col-center">
          <Popover 
            placement={(isMobile ? ("bottomRight") : ("bottom"))}
            trigger="click"
            onVisibleChange={() => setTextParaFlechaPill((textParaFlechaPill === "dificultades") ? (" ") : ("dificultades"))}
            content={
            <div className="popover-content" >
              <div className="popover-container">
                <Row>
                  <Col span={22}>
                    <p className="popover-subtitle-texto">Dificultad Física</p>
                  </Col>
                  <Col span={2}>
                    <Tooltip placement="bottomRight" title={"El numero depende del desnivel de la ruta y representa lo demandante que es un cerro. Es importante notar que el desnivel de un cerro y la distancia horizontal suelen estar a proporción."}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="info-icon">
                        <path stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                      </svg>
                    </Tooltip>
                  </Col>
                </Row>
                <span className="popover-content-title-left">{nivelFisico[0]}</span>
                <span className="popover-content-title-right">{nivelFisico[1]}</span>   
                <Slider
                  className="custom-slider"
                  getAriaLabel={() => 'Fisico'}
                  value={nivelFisico}
                  step={1} min={1} max={10}
                  onChange={(event, newValue) => setNivelFisico(newValue)}
                />

              </div>

              <div className="popover-container">
              <Row>
                  <Col span={22}>
                    <p className="popover-subtitle-texto">Dificultad Técnica</p>
                  </Col>
                  <Col span={2}>
                    <Tooltip placement="bottomRight" 
                      title={"La letra depende del conocimiento técnico que una ruta requiere. Un cerro puede abarcar varias letras, pero la dificultad muestra la letra más alta."}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="info-icon">
                        <path  stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                      </svg>
                    </Tooltip>
                  </Col>
                </Row>
                <span className="popover-content-title-left">{String.fromCharCode(nivelTecnico[0] + 65)}</span>
                <span className="popover-content-title-right">{String.fromCharCode(nivelTecnico[1] + 65)}</span>
                <Slider
                  className="custom-slider"
                  getAriaLabel={() => 'Tecnica'}
                  value={nivelTecnico}
                  step={1} min={0} max={6}
                  onChange={(event, newValue) => setNivelTecnico(newValue)}
                />

              </div>

              <div className="popover-container">
                <Row>
                  <Col span={22}>
                    <p className="popover-subtitle-texto">Exposición Solar</p>
                  </Col>
                  <Col span={2}>
                    <Tooltip placement="bottomRight" 
                    title={"La dificultad solar de una ruta depende de que tan expuesta este al sol y la temperatura del día de tu ascenso."}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="info-icon">
                        <path stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                      </svg>
                    </Tooltip>
                  </Col>
                </Row>
                <span className="popover-content-title-left">{nivelSolarToString(nivelSolar[0])}</span>
                <span className="popover-content-title-right">{nivelSolarToString(nivelSolar[1])}</span>
                <Slider
                  className="custom-slider"
                  getAriaLabel={() => 'Sol'}
                  value={nivelSolar}
                  step={1} min={1} max={3}
                  onChange={(event, newValue) => setNivelSolar(newValue)}
                />

              </div>
            </div>}>
            <div className={[((textParaFlechaPill === "dificultades") ? "popover-individual-true" : "popover-individual-false"), ((!(nivelSolar[0] === 1 && nivelTecnico[0] === 0 && nivelFisico[0] === 1 && nivelSolar[1] === 3 && nivelTecnico[1] === 6 && nivelFisico[1] === 10)) && "popover-activated")].join(' ')}>
              <div className="popover-div">
                <Row>
                  <Col span={22}>
                  <p className="popover-text">{(nivelSolar[0] === 1 && nivelTecnico[0] === 0 && nivelFisico[0] === 1 && nivelSolar[1] === 3 && nivelTecnico[1] === 6 && nivelFisico[1] === 10) 
                     ? 
                      ("Dificultades") 
                     : 
                      (nivelFisico[0] + String.fromCharCode(nivelTecnico[0] + 65) + nivelSolarToString(nivelSolar[0]) + " - " + nivelFisico[1] + String.fromCharCode(nivelTecnico[1] + 65) + nivelSolarToString(nivelSolar[1]))} </p> 
                  </Col>
                  <Col span={1}>
                  <div className="popover-flecha-icon"> {(textParaFlechaPill === "dificultades" ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="svg-arrow">
                      <path  stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="svg-arrow-rotated">
                      <path stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                    </svg>
                  ))}</div>
                  </Col>
                </Row>
              </div>
            </div>
          </Popover>
        </Col>

        {/* Mas Filtros */} 
        <Col span = {4} xs={12} sm={8} lg={4} className="popover-col-center">
          <Popover 
            placement={(isMobile ? ("bottomLeft") : ("bottom"))}
            trigger="click"
            onVisibleChange={() => setTextParaFlechaPill((textParaFlechaPill === "areas") ? (" ") : ("areas"))}
            content={
            <div className="popover-content" >
              <div className="popover-container">
                <p className="popover-subtitle-texto">Altitud de la cumbre</p>
                <span className="popover-content-title-left">{altutudmsnm[0]}m</span>
                <span className="popover-content-title-right">{altutudmsnm[1]}m</span>
                <Slider
                  className="custom-slider"
                  getAriaLabel={() => 'MSNM'}
                  value={altutudmsnm}
                  step={100} min={0} max={4000}
                  onChange={(event, newValue) => setAltitudmsnm(newValue)}
                />

                <br></br><br></br>
                <Row>
                  <Col span={20}>
                    <p className="popover-subtitle-texto">Seleccion especifica</p>
                  </Col>
                  <Col span={4} onClick={() => inicializarSelectoresPorAreas()}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="trash-icon">
                    <path stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                  </svg>

                  </Col>
                </Row>
                
                <Select
                    mode="tags"
                    placeholder="Selecciona Municipios"
                    onChange={handleChangeMunicipios}
                    value={selectedMunicipios}
                    style={{width: '100%'}}
                  >{optionMunicipios}</Select>
                  <Select
                    mode="tags"
                    placeholder="Selecciona Parques"
                    onChange={handleChangeArea}
                    value={selectedAreas}
                    style={{width: '100%'}}
                    >{optionAreas}</Select>
                    <Select
                      mode="tags"
                      placeholder="Selecciona la categoria"
                      onChange={handleChangeRecorrido}
                      value={selectedRecorridos}
                      style={{width: '100%'}}
                    >{optionRecorridos}</Select>
              </div>
            </div>}>
            <div  className={[((textParaFlechaPill === "areas") ? "popover-individual-true" : "popover-individual-false"), ((altutudmsnm[0] !== 0 || altutudmsnm[1] !== 4000) && "popover-activated")].join(' ')}>
              <div className="popover-div">
                <Row>
                  <Col span={22}>
                  <p className="popover-text">{(altutudmsnm[0] === 0 && altutudmsnm[1] === 4000) ? ("Mas Filtros") : (altutudmsnm[0] + "m - " + altutudmsnm[1] + "m" )} </p> 
                  </Col>
                  <Col span={1}>
                  <div className="popover-flecha-icon"> {(textParaFlechaPill === "areas" ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="svg-arrow">
                    <path stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                
                  ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="svg-arrow-rotated">
                    <path stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                  </svg>
                  ))}</div>
                  </Col>
                </Row>
              </div>
            </div>
          </Popover>
        </Col>

        {/* ORDENES */}
        <Col span = {4} xs={12} sm={8} lg={4} className="popover-col-center">
          <Popover 
            placement={(isMobile ? ("bottomRight") : ("bottom"))}
            trigger="click"
            onVisibleChange={() => setTextParaFlechaPill((textParaFlechaPill === "orden") ? (" ") : ("orden"))}
            content={
            <div className="popover-content-filtros" >
              <div className="popover-container">
                <p className="popover-content-title">Ordenar</p>

                <Radio.Group className="popover-radio-center" >
                  <Space direction="vertical">
                    <Radio value={1} className="radio-text-option" onChange={() => setOrden("desnivel")}>Desnivel</Radio>
                    <Radio value={3} className="radio-text-option" onChange={() => setOrden("horas")}>Horas</Radio>
                    <Radio value={5} className="radio-text-option" onChange={() => setOrden("distancia")}>Distancia</Radio>
                  </Space>
                  <Space direction="vertical">
                    <Radio value={6} className="radio-text-option" onChange={() => setOrden("dificultad solar")}>Dificultad Solar</Radio>
                    <Radio value={4} className="radio-text-option" onChange={() => setOrden("dificultad fisica")}>Dificultad Fisica</Radio>
                    <Radio value={2} className="radio-text-option" onChange={() => setOrden("dificultad tecnica")}>Dificultad Tecnica</Radio>
                  </Space>
                </Radio.Group>
                <br></br>

                <div className="popover-ordenes-content-button" onClick={() => setOrdenBool(!ordenBool)}>
                  <Row>
                    <Col>
                      <p className="popover-content-title-left">{ordenBool ? ("Mayor a Menor ") : ("Menor a Mayor ")}</p>
                    </Col>
                    
                    <Col>
                      <div  className="popover-flecha-icon"> {((ordenBool) ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 300 300" stroke-width="20" stroke="currentColor" class="svg-mountain">
                          <path stroke-linejoin="round" d="M267.2,225.92a8.38,8.38,0,0,1-3.45,1.14H172.33l-.2,0H61.72a9.33,9.33,0,0,1-7.28-15.15l55.2-69a9.33,9.33,0,0,1,13.11-1.45,9,9,0,0,1,1.45,1.45l16.28,20.34,40.65-59.09a6.75,6.75,0,0,1,8.54-2.29,6,6,0,0,1,2.61,2.29l77.07,112.05C271.79,219.42,270.83,223.77,267.2,225.92Zm-133-171.06a5.69,5.69,0,0,0,0-.72V54a8.58,8.58,0,0,0-5.75-7.21l-.35-.12-.18,0c-.22-.06-.44-.12-.67-.16h0a7.46,7.46,0,0,0-.81-.1h-.07a7.12,7.12,0,0,0-.8,0h-.08c-.26,0-.51,0-.77.06l-.1,0c-.25,0-.49.07-.74.12l-.07,0c-.22.05-.43.1-.65.17L79.81,60.38A8.57,8.57,0,1,0,85,76.72l16.39-5.16L52.59,133.1A8.56,8.56,0,0,0,66,143.74l48.87-61.69L113.43,100a8.56,8.56,0,0,0,7.84,9.23l.7,0a8.58,8.58,0,0,0,8.53-7.88l3.7-45.76c0-.17,0-.35,0-.52Z" />
                        </svg>
                    
                        ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 300 300" stroke-width="20" stroke="currentColor" class="svg-mountain-rotated">
                          <path stroke-linejoin="round" d="M255.16,235.53a8.56,8.56,0,0,1-3.45,1.12l-91.43-.53-.19,0-110.41-.63a9.34,9.34,0,0,1-7.2-15.2l55.6-68.69a9.33,9.33,0,0,1,13.12-1.38,9.53,9.53,0,0,1,1.44,1.47l16.16,20.43,41-58.85a6.75,6.75,0,0,1,8.56-2.25,6,6,0,0,1,2.59,2.31l76.42,112.49C259.79,229.06,258.81,233.39,255.16,235.53ZM41.08,152.08a8.3,8.3,0,0,0,.77.9l0,.06a7.86,7.86,0,0,0,.94.79l.14.11a8.85,8.85,0,0,0,1.06.65l0,0a9,9,0,0,0,1.11.46l.16.05a7.42,7.42,0,0,0,1.15.28l.28,0c.14,0,.28.05.43.06s.45,0,.68,0H48a7.59,7.59,0,0,0,1.07-.08l.24,0a8.43,8.43,0,0,0,1.13-.26h0l43.36-13.38a8.56,8.56,0,1,0-5.06-16.36l-16.41,5.07,49.11-61.27A8.57,8.57,0,1,0,108.1,58.5L58.88,119.9,60.44,102a8.56,8.56,0,0,0-17.06-1.48l-4,45.74c0,.16,0,.31,0,.46s0,.21,0,.31,0,.43,0,.64,0,.13,0,.19a6.83,6.83,0,0,0,.11.79v0a8.73,8.73,0,0,0,.71,2.09l.07.13a7.27,7.27,0,0,0,.65,1.07Z" />
                        </svg>
                        ))}
                      </div>
                    </Col>  
                  </Row>
                </div>

              </div>
            </div>}>
            <div className={[((textParaFlechaPill === "orden") ? "popover-individual-true" : "popover-individual-false"), ((orden !== "nombre") && "popover-activated")].join(' ')}>
              <div className="popover-div">
                <Row>
                  <Col span={22}>
                  <p className="popover-text">{((orden === "nombre") ? "Ordenes" : (orden))}</p> 
                  </Col>
                  <Col span={1}>
                  <div className="popover-flecha-icon"> {(textParaFlechaPill === "orden" ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="svg-arrow">
                  <path  stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
                
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="svg-arrow-rotated">
                    <path  stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                  </svg>
                  ))}</div>
                  </Col>
                </Row>
              </div>
            </div>
          </Popover>
        </Col>

        
      </Row>
    </>
  )
}

export default Popovers