import {useState, useEffect} from 'react'
import {Image, Row, Col} from 'antd'

import './altitud.css'

import mediaMontana from './iconos/mediaMontana.svg'
import altaMontana from './iconos/altaMontana.svg'

const AltitudHike = ({altitud, setAltitud}) => {
  return (
    <div>
    <h1 className='titulo'>¿A qué altura prefieres que esté la cumbre?</h1>
    <div className='cuadro-altitud'>
      <Row>
        <Col span={12} className='montana-opcion' 
            onClick={() => {
              if(altitud.includes('menos')) setAltitud(altitud.filter(ee => ee !== 'menos'))
              else setAltitud([...altitud, ...['menos']])
            }}>
          <div className='margin'>
            <Image 
              className='icono-montana'
              src={mediaMontana} 
              fluid 
              alt={`reloj`} 
              preview={false}
            />
            <div className={(altitud.includes('menos')) ? 'montana-opcion-button-true' : 'montana-opcion-button'}> 
              <p className="montana-opcion-button-text">
                Menos de 2500 msnm
              </p>
            </div>
          </div>

        </Col>

        
        <Col span={12} className='montana-opcion' 
            onClick={() => {
              if(altitud.includes('mas')) setAltitud(altitud.filter(ee => ee !== 'mas'))
              else setAltitud([...altitud, ...['mas']])
            }}>
        <div className='margin'>
            <Image 
              className='icono-montana'
              src={altaMontana} 
              fluid 
              alt={`reloj`} 
              preview={false}
            />
            <div className={(altitud.includes('mas')) ? 'montana-opcion-button-true' : 'montana-opcion-button'}>
              <p className="montana-opcion-button-text">
                Más de 2500 msnm
              </p>
            </div>
          </div>
        
        </Col>
      </Row>
    </div>
      
    </div>
  )
}

export default AltitudHike