import React from 'react'
import Footer from '../common/Footer'
import Header from '../common/Header'

const Dificultades = () => {
  return (
    <>
    <Header></Header>
    <br></br>
    <br></br>
    <br></br>
    <h1>dificultades</h1>
    <Footer></Footer>
    
    </>
  )
}

export default Dificultades