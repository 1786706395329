import "./faq.css";

import React, { useEffect, useState } from 'react';

import FaqElement from "./faq_element";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Row } from 'antd';


function populatePreguntas() {
    let preguntas = [];

    for (let i = 0; i < 10; i++) {
        let pregunta = "Pregunta " + i + "";
        let respuesta = "La respuesta a la pregunta #:" + i;
        preguntas.push(<FaqElement pregunta={pregunta} respuesta={respuesta} num={i} key={i} />);
    }

    return preguntas;
}

function Faq(props) {

    const [preguntas, setPreguntas] = useState([]);

    useEffect(() => {
        setPreguntas(populatePreguntas());
    }, []);

    return (
        <>
            <Header />

            <Row className="title" justify="center">
                <h1>Página de FAQ</h1>
            </Row>
            <Row>
                {preguntas}
            </Row>


            <Footer />
        </>
    );
}

export default Faq;