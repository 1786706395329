import { useState, useEffect } from 'react';
import Footer from '../common/Footer'
import Header from '../common/Header'
import './mission.css'

import MissionVision from './misionVision/misionVision';
import BackgroundImage from './backgroundImage/backgroundImage';
import QueHacemos from './queHacemos/queHacemos';
import Parallaxx from '../common/Parallaxx';
import QuienesSomos from './quienesSomos/quienesSomos';
import NuestrosAliados from './nuestrosAliados/nuestrosAliados';

const backgroundImageRutaUrl = "https://imagedelivery.net/Ld9aiKCsB0YY5djFoM113Q/f8b0cf24-779e-4b84-3f76-32e87133f800/public";

const Mission = () => {
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    // Reactive window resize
    useEffect(() => {
        window.addEventListener("resize", handleResize)
        handleResize()
    })

    return (
        <>
            <Header />
            <BackgroundImage />
            <Parallaxx backgroundImageRutaUrl={backgroundImageRutaUrl} titulo={'About us'} subtitulo={'subtitulo'}/>

            <MissionVision />
            <QueHacemos isMobile={isMobile}/>
            <QuienesSomos isMobile={isMobile} />
            <NuestrosAliados isMobile={isMobile} />
            
            <Footer />
        </>
    );
};

export default Mission;