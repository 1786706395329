import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react'

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Footer from '../common/Footer'
import Header from '../common/Header'
import TipCard from './tipCard';
import './tips.css';

import { Parallax } from 'react-parallax';
import { Typography } from 'antd';

import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { AudioOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
import env from "react-dotenv";
const { Search } = Input;
const { Title } = Typography;

const link_api = process.env.REACT_APP_API_URL;


const Tips = () => {
    const [tips, setTips] = useState([]);

    const background = "/img/Rutas/jumbotron.jpg";

    const insideStyles = {
      padding: 20,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      textAlign: "center",
      color: "whitesmoke !important",
    };

    const titles = {
      textTransform: "uppercase !important",
      fontFamily: 'Bebas Neue,cursive',
      fontWeight: "normal"
    };

    const subTitles = {
        textTransform: "uppercase !important",
        fontFamily: 'Poppins, sans-serif',
        fontWeight: "550",
        color: '#746555',
        fontSize: 17
      };

    const menuStyle = {
        borderBottom: '1px solid #64503f',
        paddingBottom: '0.5em',
        marginBottom: '0.5em',
    };

    const containsAnyLetter = (str) => {
        return /[a-zA-Z]/.test(str);
    }

    const onSearch = (value) => {
        if(!containsAnyLetter(value.target.value)){
            axios.get(link_api + '/tip')
                .then(res => {
                    setTips(res.data);
                })
            return;
        }
        const link =(link_api + '/tip/search?term=' + value.target.value);
        //console.log(link);
        try{
            axios.get(link)
                .then(res => {
                    //console.log(res.data);
                    setTips(res.data);
                })
            
        }catch(error){
            console.log(error);
        }
        //console.log(value);
    }
    


    
    useEffect(()=>{
        try{
            axios.get(link_api + '/tip')
                .then(res => {
                    setTips(res.data);
                })
        }catch(error){
            console.log(error);
        }
    },[])

    return (
        <div>
            <Header/>
            <div className="mb-5">
                {/* IMAGE */}
                <div>
                    <Parallax bgImage={background} blur={{ min: -3, max: 3 }}>
                        <div style={{ height: "100vh" }}>
                            <div style={insideStyles}>
                                <Title style={titles} className="parallax-text1">Tips</Title>
                                <Title style={titles} className="parallax-text" level={3}>Planea tu aventura</Title>
                            </div>
                        </div>
                    </Parallax>
                </div>
                <Container className="mt-5" style={{minWidth: '91%'}}>
                    {/* Title */}
                    <div>
                        <Title style={titles} className="subtitle" variant="h1" component="div"> Preparación para la montaña</Title>
                    </div>

                    {/* SEARCH */}
                    <div className="">
                        {/* <h1>search</h1> */}
                        <Search
                            defaultValue={''}
                            placeholder="input search text"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                        
                    </div>

                    {/* TIP LIST */}
                    <div className="mt-5">
                        {/* MAIN CONTAINER */}
                        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 7, sm: 7, md: 7}}>
                            {/* MENU */}
                            <Grid item xs={2} sm={1} md={1}>
                                <Grid container columns={{ xs: 1, sm: 1, md: 1}}>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <Typography style={Object.assign({},subTitles, menuStyle)} variant="h1" component="div"> Seguridad</Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <Typography style={Object.assign({},subTitles, menuStyle)} variant="h1" component="div"> Advertencias</Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <Typography style={Object.assign({},subTitles, menuStyle)} variant="h1" component="div"> Técnica segura</Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <Typography style={subTitles}variant="h1" component="div"> Responsabilidad ambiental</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* TIPS*/}
                            <Grid item xs={5} sm={6} md={6}> 
                                <Grid container spacing={{ xs: 3, md: 2 }} columns={{ xs: 1, sm: 8, md: 12}}>
                                    {
                                        ((tips.length) ? 
                                    tips.map(tip => {
                                        return(
                                            <Grid item key={tip._id} xs={2} sm={4} md={4}>
                                                <TipCard data={tip}/>
                                            </Grid>
                                        )
                                    }) : (<h1>no hay resultados</h1>))}
                                </Grid> 
                            </Grid>
                        </Grid>
                         
                    </div>
                    {/* FAQ */}
                    <div className="mt-5 faqContainer">
                        <Title style={titles} className="subtitle" variant="h1" component="div" > ¿No ves lo que estás buscando?</Title>
                        <Typography style={subTitles} variant="h1" component="div"> Visita nuestra sección de <a>Preguntas Frecuentes</a></Typography>
                         
                    </div>
                </Container>
            </div>
            <Footer/>
        </div>
    )
}

export default Tips