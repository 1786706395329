import { React, useEffect, useState } from "react";

import 'antd/dist/antd.min.css'; 
import './cardsRutas.css';
import RouteCard from "./RouteCard";


import { Col, Row, Layout } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";

const CardsRutas = ({showRoutes}) => {
  return (
    <>
        <div className="tarjetas-rutas" >
            <Row gutter={[8, 24]}>                
                {/* populate col with routecard for each route, if empty display loading animation*/}
                {
                  showRoutes>0?
                  <div style={{width:"100%"}}>
                    <p style={{textAlign:"center"}}><LoadingOutlined style={{fontSize:"40vh"}}/></p>
                  </div>
                :
                  (showRoutes.map(route => (
                      <Col  span={6} xs={24} sm={12} md={8} lg={6} key={route._id} className="tarjeta-ruta-centrada">
                        <RouteCard route={route} />
                      </Col>
                    )))
                }
            </Row>
          </div>
    </>
  )
}

export default CardsRutas