import React from "react";
import Footer from '../common/Footer'
import Header from '../common/Header'
import Parallaxx from "../common/Parallaxx"
import ProgressBar from "./progressBar";
import Animator from "./animator";
import { Card } from 'antd';

import { useEffect, useState } from "react";

import 'antd/dist/antd.min.css';
import './styles.css';
import { Carousel, Divider } from 'antd';
import HelmetComponent from "../common/helmet/helmet";
import { Layout } from 'antd';
import { Col, Row } from 'antd';
import { Image } from 'antd';

const { Content } = Layout;
const { Meta } = Card;
const backgroundImageRutaUrl = "https://imagedelivery.net/Ld9aiKCsB0YY5djFoM113Q/d3d84dfb-c25c-465a-679c-f56af28ab000/public";

const Home = () => {
  const [isMobile, setIsMobile] = useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize();
  });

    return (
    <>
        <HelmetComponent 
          titulo={"Home de senderismo"} 
          descripcion={"Conoce nuestra historia"} 
          imagenURL={backgroundImageRutaUrl} 
          imageAlt={"Senderistas caminando en la sierra de Arteaga, Coahuila"} 
        />
        <Header/>
        
        {/* Parallax */}
        <Parallaxx backgroundImageRutaUrl={backgroundImageRutaUrl} titulo={'Seamos Nómadas'} subtitulo={' '}/>
        <ProgressBar/>
        <Animator/>
{/* 
        <Carousel autoplay>
          <div>
            <h3 style={{fontSize:"10vh",paddingTop:"40vh", height: '100vh', color: '#fff', lineHeight: '160px', textAlign: 'center',
            backgroundImage: "url(/img/Rutas/jumbotron.jpg)",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
              Rutas
            </h3>
          </div>
          <div>
          <h3 style={{fontSize:"10vh",paddingTop:"40vh", height: '100vh', color: '#fff', lineHeight: '160px', textAlign: 'center',
            backgroundImage: "url(/img/Rutas/jumbotron.jpg)",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
              Tips
            </h3>
          </div>
          <div>
            <h3 style={{fontSize:"10vh",paddingTop:"40vh", height: '100vh', color: '#fff', lineHeight: '160px', textAlign: 'center',
            backgroundImage: "url(/img/Rutas/jumbotron.jpg)",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
              Mision
            </h3>
          </div>
          <div>
          <h3 style={{fontSize:"10vh",paddingTop:"40vh", height: '100vh', color: '#fff', lineHeight: '160px', textAlign: 'center',
            backgroundImage: "url(/img/Rutas/jumbotron.jpg)",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
              Planea
            </h3>
          </div>
          <div>
          <h3 style={{fontSize:"10vh",paddingTop:"40vh", height: '100vh', color: '#fff', lineHeight: '160px', textAlign: 'center',
            backgroundImage: "url(/img/Rutas/jumbotron.jpg)",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
              Proyectos
            </h3>
          </div>
        </Carousel>
*/}
        <Content className="contentHome">
          <Row justify="space-evenly" className="home-container-1 animate fade-t">
            <Col span={8} xs={24} sm={24} md={6} lg={6}>
              <div className="cuadro-verde">
                <Image 
                    src='img\Íconos\Mision\Misión.webp' 
                    fluid 
                    preview={false}
                    alt={`Dificultad fisica`} 
                />
              </div>
              <h2 className="home-title-1">Misión</h2>
              <p className="home-text-1">Hacer el senderismo más accesible a través de educación, señalética y mantenimiento de veredas.</p>
            </Col>
            <Col span={8} xs={24} sm={24} md={6} lg={6}>
              <div className="cuadro-verde">
                <Image 
                    src='img\Íconos\Mision\Visión.webp' 
                    fluid 
                    preview={false}
                    alt={`Dificultad fisica`} 
                />
              </div>
              <h2 className="home-title-1">Visión</h2>
              <p className="home-text-1">Proteger nuestras áreas naturales para asegurar un medio ambiente sano y sostenible.</p>
            </Col>
            <Col span={8} xs={24} sm={24} md={6} lg={6}>
              <div className="cuadro-verde">
                <Image 
                    src='img\Íconos\Mision\Propósito.webp' 
                    fluid 
                    preview={false}
                    alt={`Dificultad fisica`} 
                />
              </div>
              <h2 className="home-title-1">Propósito</h2>
              <p className="home-text-1">Crear una comunidad de senderistas responsables estableciendo un valor ecoturístico a nuestras áreas naturales.</p>
            </Col>
          </Row>

          <Row className="home-container-2 animate fade-t">
            
            <img className="home-img-1" src="https://user-images.githubusercontent.com/58484877/198415648-9948d8f3-a24e-44e5-95f0-244e1e7a6f27.png" alt="holder-1"></img>
            
              <h1 className="home-title-2">Inicios</h1>
              <p className="home-text-2">La Cumbre Cotidiana comenzó como un proyecto pequeño con el propósito de informar sobre la manera segura y sustentable de visitar la montaña. En dos años nos convertimos en una Asociación sin fines de lucro y nuestro equipo creció a un total de 60 personas cuyo objeto social es proteger nuestras áreas naturales a través del senderismo.</p>
            
          </Row>

          <Row className="home-container-3 animate fade-t" justify="space-evenly">
            <Col span={10} xs={20} sm={20} md={8} lg={8}>
              <div className="greenCircle"></div>
              <h1 className="home-title-3">Reforestación Incendio 2021</h1>
              <p className="home-text-3">Apoyamos con la reforestación dentro del Parque Nacional Cumbres de Monterrey en un lugar crítico para la futura restauración de la zona.</p>
              {/* <p className="home-text-4">Leer Más</p>*/}
            </Col>
            <Col span={10} xs={20} sm={20} md={8} lg={8}><img className="home-img-2" src="/img/Rutas/jumbotron.jpg" alt="holder-2"></img></Col>
          </Row>
    <Divider />

          <Row className="home-container-3 animate fade-t" justify="space-evenly">
          {!isMobile && (<Col span={10} xs={20} sm={20} md={8} lg={8}><img className="home-img-2" src="/img/Rutas/jumbotron.jpg" alt="holder-2"></img></Col>)}
            <Col span={10} xs={20} sm={20} md={8} lg={8}>
              <div className="greenCircle"></div>
              <h1 className="home-title-3">Señalización Cerro de las Mitras</h1>
              <p className="home-text-3">Instalamos señalética de madera en el ascenso al cerro de las mitras por la subida del colegio kilimanjaro, ofreciendo información de la ruta a través de códigos QR.</p>
              {/* <p className="home-text-4">Leer Más</p>*/}
            </Col>
            {isMobile && (<Col span={10} xs={20} sm={20} md={8} lg={8}><img className="home-img-2" src="/img/Rutas/jumbotron.jpg" alt="holder-2"></img></Col>)}

          </Row>
    <Divider />

          <Row className="home-container-3 animate fade-t" justify="space-evenly">
            <Col span={10} xs={20} sm={20} md={8} lg={8}>
              <div className="greenCircle"></div>
              <h1 className="home-title-3">Replaqueteo de Cañón de las reflexiones</h1>
              <p className="home-text-3">Se instalaron y reemplazaron anclajes a lo largo del Cañón de las Reflexiones en los límites entre Coahuila y Nuevo León por la carretera Monterrey-Saltillo. Rehabilitamos 5 de los 7 rapeles. </p>
              {/* <p className="home-text-4">Leer Más</p>*/}
            </Col>
            <Col span={10} xs={20} sm={20} md={8} lg={8}><img className="home-img-2" src="/img/Rutas/jumbotron.jpg" alt="holder-2"></img></Col>
          </Row>
    <Divider />

          <Row className="home-container-3 animate fade-t" justify="space-evenly">
          {!isMobile && (<Col span={10} xs={20} sm={20} md={8} lg={8}><img className="home-img-2" src="/img/Rutas/jumbotron.jpg" alt="holder-2"></img></Col>)}
            <Col span={10} xs={20} sm={20} md={8} lg={8}>
              <div className="greenCircle"></div>
              <h1 className="home-title-3">Limpieza Graffiti</h1>
              <p className="home-text-3">Eliminamos la pintura de 18 piedras grafiteadas ubicadas en el Cañón de San Bartolo dentro del Parque la Huasteca.</p>
              {/* <p className="home-text-4">Leer Más</p>*/}
            </Col>
            {isMobile && (<Col span={10} xs={20} sm={20} md={8} lg={8}><img className="home-img-2" src="/img/Rutas/jumbotron.jpg" alt="holder-2"></img></Col>)}

          </Row>
    <Divider />
{/* 
          <Row className="home-container-2 animate fade-t">
            <img className="home-img-1" src="https://user-images.githubusercontent.com/58484877/198415648-9948d8f3-a24e-44e5-95f0-244e1e7a6f27.png" alt="holder-1"></img>
            
              <h1 className="home-title-2">2020</h1>
              <p className="home-text-2">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta totam, molestias magni illum amet repudiandae accusantium odit quos. Et nostrum magnam repellendus ut ad ea, culpa fuga iure laborum nemo.</p>
            
          </Row>
*/}
          <div className="home-container-4 animate fade-t">
            <br></br>
            <div className="home-container-content-4">
            <Row gutter={[48,8]}>
              <Col span={24} md={12}>
                <h2 className="home-container-content-text-titulo-4">De Senderistas Para Senderistas</h2>
                <p className="home-container-content-text-parrafo-4">
                  Clasificar una ruta de hiking usando palabras como fácil - intermedio - difícil es muy genérico y puede ser la causa de un accidente. Por varios meses nosotros hemos clasificado rutas usando estas escalas. Esto cambia hoy. Los invitamos a ustedes, ya sean cuentas de hiking o personas individuales, a usar la clasificación que desarrollamos.
                  <br/>
                  Dificultad física del 1-10 dependiendo en desnivel. 
                  <br/>
                  Dificultad técnica de A-G dependiendo en conocimiento técnico. 
                  <br/>
                  Exposición al sol de I-III dependiendo en la sombra que hay en un recorrido. 
                  <br/>
                  Es muy simple y ayuda a conceptualizar tu mejoramiento en la montaña.
                </p>
              </Col>
              <Col span={24} md={12}>
                <Image  src="https://user-images.githubusercontent.com/58484877/198416795-088b9bda-bf3c-4ad5-b200-bbe5e881ea3f.png" alt="holder-1"/>
              </Col>

            </Row>
            </div>
            <br/>
          </div>

          <div className="home-container-5 animate fade-t">
            <br/>
            <div  className="home-container-content-5"> 
              <h1 className="home-container-content-titulo-5">Involucrate / sobrenosotros </h1>
              <h5  className="home-container-content-parrafo-5">La Cumbre Cotidiana es una organización sin fines de lucro por lo que tu apoyo es de vital importancia.</h5>
              <br/>
                  <Row gutter={[48,48]}>
                    <Col sm={12} xs={24} lg={8}  style={{display: "flex", justifyContent: "center"}}>
                      <Card
                        className="home-container-cards"
                        cover={<img alt="example" src="https://user-images.githubusercontent.com/58484877/198416795-088b9bda-bf3c-4ad5-b200-bbe5e881ea3f.png" />}
                      >
                        <h2 className="home-container-card-titulo-5">Únete al Equipo</h2>
                        <div className="home-container-card-button-5">
                          <h4 className="home-container-card-button-text-5">
                            Saber Mas
                          </h4>
                        </div>
                      </Card>
                    </Col>
                    <Col  sm={12} xs={24} lg={8} style={{display: "flex", justifyContent: "center"}}>
                      <Card
                        className="home-container-cards"
                        cover={<img alt="example" src="https://user-images.githubusercontent.com/58484877/198416795-088b9bda-bf3c-4ad5-b200-bbe5e881ea3f.png" />}
                      >
                        <h2 className="home-container-card-titulo-5">Voluntareados </h2>
                        <div className="home-container-card-button-5">
                          <h4 className="home-container-card-button-text-5">
                            Saber Mas
                          </h4>
                        </div>
                      </Card>
                    </Col>
                    <Col sm={12} xs={24} lg={8}style={{display: "flex", justifyContent: "center"}}>
                      <Card
                        className="home-container-cards"
                        cover={<img alt="example" src="https://user-images.githubusercontent.com/58484877/198416795-088b9bda-bf3c-4ad5-b200-bbe5e881ea3f.png" />}
                      >
                        <h2 className="home-container-card-titulo-5">Haste Miembro</h2>
                        <div className="home-container-card-button-5">
                          <h4 className="home-container-card-button-text-5">
                            Saber Mas
                          </h4>
                        </div>
                      </Card>
                    </Col>
                <Col span={2}>
                
                </Col>
                  </Row>
            </div>
          </div>
{/* 
          <Row className="home-container-2 animate fade-t">
            <img className="home-img-1" src="https://user-images.githubusercontent.com/58484877/198415648-9948d8f3-a24e-44e5-95f0-244e1e7a6f27.png" alt="holder-1"></img>
            {!isMobile && (
            <h1 className="home-title-5">simply dummy text of the printing and typesetting industry</h1>
            )}
            <div className="home-container-div-button-6">
              <h5 className="home-container-div-text-6">donar</h5>
            </div>
          </Row>
*/}
        </Content>
        <Footer/>
    </>
  );
};
export default Home;