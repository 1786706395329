import {useState, useEffect} from 'react'
import {Image, Row, Col} from 'antd'


import Sol1 from './iconos/Sol1.svg'
import Sol2 from './iconos/Sol2.svg'
import Sol3 from './iconos/Sol3.svg'
import SolIcon1 from './iconos/SolIcon1.svg'
import SolIcon2 from './iconos/SolIcon2.svg'
import SolIcon3 from './iconos/SolIcon3.svg'

import './sol.css'

const SolHike = ({sol, setSol}) => {
  const [isMobile, setIsMobile] = useState(false)

  const handleResize = () => {
    if (window.innerWidth < 576) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize();
  });


  return (
    <>
      <h1 className='titulo'>¿Qué tan expuesto al sol?</h1>

      <div className='cuadro-sol'>
        <Row>
          <Col sm={8} xs={24} className='sombra-opcion' 
            onClick={() => {
            if(sol.includes(1)) setSol(sol.filter(ee => ee !== 1))
            else setSol([...sol, ...[1]])
          }}>
            <div>
              <Row>
                <Col sm={24} xs={12} >
                  {!isMobile && (
                    <div className='icono-sombra-clasificacion'>
                      <Image 
                        src={SolIcon1} 
                        fluid 
                        alt={`reloj`} 
                        preview={false}
                      />
                    </div>
                  )}
                  <div className='icono-sombra-arbol'>
                    <Image 
                      src={Sol1} 
                      fluid 
                      alt={`reloj`} 
                      preview={false}
                    />
                  </div>
                </Col>
                <Col sm={24} xs={12} >
                  {isMobile && (
                    <div className='icono-sombra-clasificacion'>
                      <Image 
                        src={SolIcon1} 
                        fluid 
                        alt={`reloj`} 
                        preview={false}
                      />
                    </div>
                  )}
                  <div className={(isMobile ? "margin-top " : "") + ((sol.includes(1)) ? ('sombra-opcion-button-true') : ('sombra-opcion-button'))}>
                    <p className="sombra-opcion-button-text">
                      Mucha Sombra
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={8} xs={24} className='sombra-opcion' 
            onClick={() => {
            if(sol.includes(2)) setSol(sol.filter(ee => ee !== 2))
            else setSol([...sol, ...[2]])
          }}>
            <div className='margin'>
              <Row>
                <Col sm={24} xs={12} >
                  {!isMobile && (
                    <div className='icono-sombra-clasificacion'>
                      <Image 
                        src={SolIcon2} 
                        fluid 
                        alt={`reloj`} 
                        preview={false}
                      />
                    </div>
                  )}
                  <div className='icono-sombra-arbol'>
                    <Image 
                      src={Sol2} 
                      fluid 
                      alt={`reloj`} 
                      preview={false}
                    />
                  </div>
                </Col>
                <Col sm={24} xs={12} >
                  {isMobile && (
                    <div className='icono-sombra-clasificacion'>
                      <Image 
                        src={SolIcon2} 
                        fluid 
                        alt={`reloj`} 
                        preview={false}
                      />
                    </div>
                  )}
                  <div className={(isMobile ? "margin-top " : "") + ((sol.includes(2)) ? ('sombra-opcion-button-true') : ('sombra-opcion-button'))}>
                    <p className="sombra-opcion-button-text">
                      Algo Sombra
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={8} xs={24} className='sombra-opcion' 
            onClick={() => {
            if(sol.includes(3)) setSol(sol.filter(ee => ee !== 3))
            else setSol([...sol, ...[3]])
          }}>
            <div className='margin'>
              <Row>
                <Col sm={24} xs={12} >
                  {!isMobile && (
                    <div className='icono-sombra-clasificacion'>
                      <Image 
                        src={SolIcon3} 
                        fluid 
                        alt={`reloj`} 
                        preview={false}
                      />
                    </div>
                  )}
                  <div className='icono-sombra-arbol'>
                    <Image 
                      src={Sol3} 
                      fluid 
                      alt={`reloj`} 
                      preview={false}
                    />
                  </div>
                </Col>
                <Col sm={24} xs={12} >
                  {isMobile && (
                    <div className='icono-sombra-clasificacion'>
                      <Image 
                        src={SolIcon3} 
                        fluid 
                        alt={`reloj`} 
                        preview={false}
                      />
                    </div>
                  )}
                  <div className={(isMobile ? "margin-top " : "") + ((sol.includes(3)) ? ('sombra-opcion-button-true') : ('sombra-opcion-button'))}>
                    <p className="sombra-opcion-button-text">
                      No hay Sombra
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>

      </Row>
    </div>
      
    </>
  )
}

export default SolHike