import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ScrollToTop from "./components/common/scrollToTop";

import Home from "./components/Home";
import Tips from "./components/Tips";
import Tip from "./components/Tip";
import Mission from "./components/Mission/mission";
import Rutas from "./components/Rutas";
import Ruta from "./components/Ruta";
import Planea3 from "./components/Planea3";
import Planea from "./components/Planea";
import Dificultades from "./components/Dificultades";
import Faq from "./components/FAQ/faq";

import NotFound from "./components/NotFound";
import Proyectos from "./components/Proyectos"


import ReactGA from "react-ga4";

ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

const Router = () => {
  return (
    <>

      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ScrollToTop />
        <Routes>

          <Route exact path="/" element={<Home />} />

          <Route exact path="/tips" element={<Tips />} />
          <Route path="/tips/:id" element={<Tip />} />

          <Route exact path="/ruta" element={<Rutas />} />
          <Route path="/ruta/:id" element={<Ruta />} />


          <Route exact path="/planea" element={<Planea3 />} />
          <Route exact path="/planea2" element={<Planea />} />

          <Route exact path="/Nosotros" element={<Mission />} />
          <Route exact path="/proyectos" element={<Proyectos />} />
          <Route exact path="/dificultades" element={<Dificultades />} />
          <Route exact path="/faq" element={<Faq />} />


          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>

    </>
  );
};

export default Router;
