import React from 'react'

import './backgroundImage.css'

const BackgroundImage = () => {
  return (
    <div className="background_wrap" />
  )
}

export default BackgroundImage