import 'antd/dist/antd.min.css'; 

import { Typography } from 'antd';
import './styles.css';
const { Title } = Typography;
const Parallaxx = ({backgroundImageRutaUrl, titulo, subtitulo}) => {
  
  return (
    <>
      <div className="container">
        <img src={backgroundImageRutaUrl} alt="Senderistas caminando" className='imagen'/>
        <div className="centered">
          
            <Title className="parallax-title">{titulo}</Title>
            <Title  className="parallax-textt" level={2}>{subtitulo}</Title>
        </div>
      </div>
    
    </>
  )
}

export default Parallaxx