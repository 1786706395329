import { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import { Avatar, Card } from 'antd';
import { Tabs } from 'antd';
import { Col, Row } from 'antd';
import './quienesSomos.css'
import axios from 'axios';
import Container from 'react-bootstrap/Container';


const { Meta } = Card;
const { TabPane } = Tabs;

const link_api = process.env.REACT_APP_API_MONGO_URL;


const QuienesSomos = ({isMobile}) => {

    const [uniqueTabs, setUniqueTabs] = useState([]);
    const [equipos, setEquipos] = useState([]);
    const [mostrarEquipos, setMostrarEquipos] = useState([]);

    useEffect(() => {
        const set = new Set();

        try {
            axios.get(link_api + '/equipos')
                .then(res => {
                    //console.log(res.data);
                    setEquipos(res.data);
                    res.data.forEach(element => {
                        set.add(element.puesto);
                    });
                    const ar = Array.from(set)
                    setUniqueTabs(ar);
                })
        } catch (err) {
            console.log(err);
        }
    }, [])
    

    // Mostrar Equipos
    useEffect(() => {
        let tabs = [];
        let index = 0;

        uniqueTabs.forEach(equipo => {
            const teamSeparado = equipos.filter(personaTeam => (personaTeam.puesto === equipo));


            tabs.push(
                <TabPane tab={equipo.toUpperCase()} key={index++}  >
                    <Row justify='center'>
                            {teamSeparado.map(miembro =>
                                <Col >
                                    <Card className='miembros-card'>
                                        <Row>
                                            <Col span={24} sm={12}>
                                                <Row justify='center' className='equipos-container'>
                                                    <Meta avatar={<Avatar shape="circle" size={(isMobile ? 130 : 140)} src={miembro.foto} />} />
                                                </Row>
                                            </Col>
                                            <Col span={24} sm={12}>
                                                <Row justify='center'>
                                                    <h1 className="texto-miembros-nombre">{miembro.nombre}</h1>
                                                </Row>
                                                <Row justify='center'>
                                                    <h2 className="texto-miembros-descripcion">{miembro.descripcion}</h2>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            )}
                    </Row>
                </TabPane>
            );
        });


        setMostrarEquipos(tabs);
        

    }, [equipos, isMobile, uniqueTabs])

  return (
    <div className="quienes-somos-container">
        <br></br>
            <Row>
                <Col span={2}></Col>
                <Col span={22} >
                    <div className="quienes-somos-titulo-container">
                        <h1 className="quienes-somos-texto-container">¿Quiénes somos?</h1>
                    </div>
                </Col>
                <Col span={2}></Col>
            </Row>
            <Row>
                <Col span={5}></Col>
                <Col span={14}>
                    <Tabs defaultActiveKey="1" size="large" centered>
                        {mostrarEquipos}
                    </Tabs>
                </Col>
                <Col span={5}></Col>
            </Row>
        <br></br>
    </div>
  )
}

export default QuienesSomos