import { React, useEffect, useState } from "react";
import Footer from '../common/Footer'
import Header from '../common/Header'

import 'antd/dist/antd.min.css'; 
import './tip.css';

import parse, { domToReact  }  from 'html-react-parser';
import { useNavigate } from "react-router-dom";
import { useParams,NavLink  } from 'react-router-dom';

import { Parallax } from 'react-parallax';
import { Input, Layout, Divider, Typography, Col, Row, Radio} from 'antd';
import { Select } from 'antd';
import { LoadingOutlined, SearchOutlined, DownOutlined, CaretDownOutlined, UpOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space, Image, Popover, Tooltip  } from 'antd';
import Button from '@mui/material/Button';
import axios from 'axios';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const link_api = process.env.REACT_APP_API_URL;

const { Title } = Typography;
const { Content } = Layout;
const { Search } = Input;
const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) {
        return;
      }
  
      if (attribs.id === 'main') {
        return <h1 style={{ fontSize: 42 }}>{domToReact(children, options)}</h1>;
      }
  
      if (attribs.class === 'prettify') {
        return (
          <span style={{ color: 'hotpink' }}>
            {domToReact(children, options)}
          </span>
        );
      }
    }
  };
  

const Tip = () => {
  const [isMobile, setIsMobile] = useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 600) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize();
  })

    const [data, setData] = useState({});
    const { id } = useParams();
    const insideStyles = {
        padding: 20,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        textAlign: "center",
        color: "whitesmoke !important",
      };
    
    
      const titles = {
        textTransform: "uppercase !important",
        fontFamily: 'Bebas Neue,cursive',
        fontWeight: "normal"
      };
    
    useEffect(()=>{
        try{
            console.log(id)
             const link_api = `https://la-cumbre-cotidiana-api.herokuapp.com/api/tip/listarIndividual/?name=${id}`;
             axios.get(link_api)
                 .then(res => {
                     console.log(res.data);
                     setData(res.data);
                 })
        }catch(error){
            
            console.log(error);
        }
    },[])
    if(data.imagenPortada !== undefined){
    return (
        <>
        <Header/>

        {/* Parallax */}
        <div className="content-box">
          <Parallax  bgImage={data.imagenPortada.file} blur={{ min: -3, max: 3 }}>
            <div style={{ height: "100vh" }}>
              <div style={insideStyles}>
                <Title style={titles} className="parallax-text1">{data.titulo}</Title>
                <Title style={titles} className="parallax-text" level={3}>{data.subtitulo}</Title>
              </div>
            </div>
          </Parallax>
        </div>
        <br></br>
        <div className="main-content">
          <div className="asdf">
            <h5 className="texto-descripcion-basica">
                {data.descripcionBasica}
            </h5>
            <p>Actualizado: {(data.updatedAt).substring(10,0)}</p>
          </div>
            
            {data.contenidos.map((contenido, key) => {
              if(isMobile){
                return (<>
                  <Divider />
                      <Row>
                        <h2 className="contenido-titulo">{contenido.titulo}</h2>
                        <p className="contenido-texto">{parse(contenido.texto)}</p>
                        <div style={{"margin": "auto"}}>
                          <Image className="imagen"  src={contenido.img.file} />
                        </div>
                      </Row>
                    </>)
              }else{
                return (<>
                <Divider />
                {(key % 2 == 1) ? 
                  (
                    <>
                      <Row wrap={false}>
                        <Col flex="auto"> 
                            <h2 className="contenido-titulo">{contenido.titulo}</h2>
                            <p className="contenido-texto" >{parse(contenido.texto)}</p>

                        </Col>
                        <Col flex="300px" offset={1}>
                          <Image className="imagen" width={300} src={contenido.img.file} />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row wrap={false}>
                        <Col flex="300px">
                          <Image className="imagen" width={300} src={contenido.img.file} />
                        </Col>
                        <Col flex="auto" offset={1}>
                          <h2 className="contenido-titulo">{contenido.titulo}</h2>
                          <p className="contenido-texto">{parse(contenido.texto)}</p>
                        </Col>
                      </Row>
                    </>
                  )
                  }
                  </>)
                }
            })}
            <Divider />
            
            {
              (data.referencias.length === 0) ? (
                <h3>No se usaron referencias</h3>
              ) : (
                <>
                  <h2>Referencias</h2>
                  {data.referencias.map(contenido => {
                    console.log(contenido);
                    return (<>
                      <h3>{contenido.descripcion}</h3>
                      <h3>{contenido.url}</h3>
                    </>)
                  })}
                </>
              )
            }
            <br></br>
        </div>
            

        <Footer/>
        </>
      )}
      else{
        return (<h1>ESQUELETO</h1>)
      }
    }

export default Tip;