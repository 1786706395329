import React from "react";
import Footer from '../common/Footer'
import Header from '../common/Header'

import { Parallax } from 'react-parallax';

const Proyectos = () => {
  return (
    <>
    <Header />
    <div>
      <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={'./img/Rutas/jumbotron.jpg'}
          bgImageAlt="Jumbotron"
          strength={-200}
      >
          
          <div style={{ height: '100vh' }}/>
      </Parallax>
      <h1>SOY PROYECTOS</h1>
    </div>

    
    <Footer />
</>
  )
}

export default Proyectos