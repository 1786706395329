import React, { useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import "./Planea.css";
import Footer from '../common/Footer'
import Header from '../common/Header'
import toast, { Toaster } from 'react-hot-toast';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import { Typography } from 'antd';

import { Col, Row } from 'antd';

import RouteCard from './RouteCard';
import RouteCardRutas from '../Rutas/RouteCard';

const { Title } = Typography;

const Planea = () => {
    
    const [resultados, setResultados] = useState([]);
    const [loadedData, setLoadedData] = useState(false);
    const [rutas, setRutas] = useState([]);
    const [page, setPage] = useState(0);
    const [opciones, setOpciones] = useState({
        nivelFisico: [{opcion: 1, valor: 1, desnivel: "0-400m", selected:false}, {opcion: 2, valor: 2, desnivel: "400-600m", selected:false}, {opcion: 3, valor: 3, desnivel: "600-800m", selected:false}, {opcion: 4, valor: 4, desnivel: "800-1000m", selected:false}, {opcion: 5, valor: 5, desnivel: "1000-1200m", selected:false}, {opcion: 6, valor: 6, desnivel: "1200-1400m", selected:false}, {opcion: 7, valor: 7, desnivel: "14000-1700m", selected:false}, {opcion: 8, valor: 8, desnivel: "1700-2000m", selected:false}, {opcion: 9, valor: 9, desnivel: "2000-2300m", selected:false}, {opcion: 10, valor: 10, desnivel: "2300 + m", selected:false}],
        nivelTecnico: [{opcion: "A", valor: 1, selected:false}, {opcion: "B", valor: 2, selected:false}, {opcion: "C", valor: 3, selected:false}, {opcion: "D", valor: 4, selected:false}, {opcion: "E", valor: 5, selected:false}, {opcion: "F", valor: 6, selected:false}],
        nivelSolar: [{opcion: "Mucha Sombra", valor: 1, selected: false}, {opcion: "Algo de Sombra", valor: 2, selected: false}, {opcion: "No hay Sombra", valor: 3, selected: false}],
        altutudmsnm: [{opcion: "Menos de 2500 msnm", valor: 1, selected: false}, {opcion: "Mas de 2500 msnm", valor: 2, selected: false}],
        horas: [{opcion: "Sólo tengo medio día", valor: 3, selected: false}, {opcion: "Tengo el día entero", valor: 6, selected: false}]
    });
    const [filtro, setFiltro] = useState({
        nivelFisico: [],
        nivelTecnico: [],
        nivelSolar: [],
        altutudmsnm: [],
        horas: []
    })

    const notify = (type) => {
        if(type === "noResults"){
            toast.error('Lo sentimos, no tenemos rutas disponibles con tu última elección. Por favor, elije una de las otras opciones.');
        }
    }

    const selectButton = (value, id) => {
        const index = opciones[id].findIndex(object => {
            return object.valor === Number(value);
        });
        
        let nuevasOpciones = opciones;

        nuevasOpciones[id][index].selected = true;

        setOpciones(nuevasOpciones);
    }

    const unSelectButton = (value, id) => {
        const index = opciones[id].findIndex(object => {
            return object.valor === Number(value);
        });
        
        let nuevasOpciones = opciones;
        nuevasOpciones[id][index].selected = false;

        setOpciones(nuevasOpciones);
    }

    const unSelectAll = () => {
        Object.keys(opciones).map((key, index) => {
            for(let i = 0; i < opciones[key].length; i++){
                opciones[key][i].selected = false;
            }
        });
    }

    const getPaginasFiltradas = () => {
        let paginasFiltradas = [];
        Object.keys(filtro).map((key, index) => {
            if(filtro[key].length > 0){
                paginasFiltradas.push(key);
            }
        });

        return paginasFiltradas;
    }

    const getResults = (resultados, id) => {

        let nuevosResultados = [];
        
        switch (id) {
            case 'nivelFisico':
                nuevosResultados = resultados.filter(ruta => {
                    if(filtro.nivelFisico.includes(ruta.nivelFisico)){
                        return ruta;
                    }
                })
                break;
            case 'nivelTecnico':
                nuevosResultados = resultados.filter(ruta => {
                    if(filtro.nivelTecnico.includes(ruta.nivelTecnico)){
                        return ruta;
                    }
                })
                break;
            case 'horas':
                nuevosResultados = resultados.filter(ruta => {
                    filtro.horas.sort();
                    if(filtro.horas[filtro.horas.length - 1] >= ruta.horas){
                        return ruta;
                    }
                })
                break;
            case 'altutudmsnm':
                nuevosResultados = resultados.filter(ruta => {
                    //Hay dos opciones seleccionadas
                    if(filtro.altutudmsnm.lenght > 1){
                        return ruta;
                    }
                    else{
                        if(filtro.altutudmsnm[0] == 1 && ruta.altutudmsnm < 2500){
                            return ruta;
                        }
                        else if(filtro.altutudmsnm[0] == 2 && ruta.altutudmsnm > 2500){
                            return ruta;
                        }
                    }
                })
                break;
            case 'nivelSolar':
                nuevosResultados = resultados.filter(ruta => {
                    if(filtro.nivelSolar.includes(ruta.nivelSolar)){
                        return ruta;
                    }
                })
                break;
            default:    
                break;
        }

        return nuevosResultados;
    }

    const filterRoutesAux = (paginasFiltradas) => {
        
        let resultados = rutas;
        if(paginasFiltradas.length > 0){
            for(let i = 0; i < paginasFiltradas.length; i++){
                resultados = getResults(resultados, paginasFiltradas[i]);
            }
        }
        return resultados;
        
    }

    const filterRoutes2 = () => {
        const paginasFiltradas = getPaginasFiltradas();
        const resultados = filterRoutesAux(paginasFiltradas);
        console.log(resultados);
        //Si no hay resultados
        //Si quieren quitar el Toast quitar el if
        if(resultados.length === 0){
            notify("noResults");
        }
        else{
            setResultados(resultados);
        }
        
    }

    const updateFilterObject = (e) => {
        //Update objeto de filter
        const id = e.target.id;
        let value = e.target.value;
        const selected = e.target.selected;
        let currentValues = [];
        switch (id) {
            case 'nivelFisico':
                currentValues = filtro.nivelFisico;
                value = Number(value);
                break;
            case 'nivelTecnico':
                currentValues = filtro.nivelTecnico;
                value = Number(value);
                break;
            case 'nivelSolar':
                currentValues = filtro.nivelSolar;
                value = Number(value);
                break;
            case 'altutudmsnm':
                currentValues = filtro.altutudmsnm;
                break;
            case 'horas':
                currentValues = filtro.horas;
                value = Number(value);
                break;
            default:
                break;
        }
        
        //Si no se encuentra en el arreglo
        
        if(!currentValues.includes(value)){
            currentValues.push(value);
            selectButton(value, id);
        }
        //Si ya se encuentra en el arreglo y el usuario lo volvió a seleccionar significa que ya no lo quiere
        else{
            let idxOfValue = currentValues.indexOf(value);
            currentValues.splice(idxOfValue, 1);
            unSelectButton(value, id);
        }

        setFiltro(prevFiltro => ({
            ...prevFiltro,
            [id]: currentValues
        }));
    }

    const changePage = (change) => {
        let newPage = page;
        if(change === "atras" && page > 0){
            newPage = page - 1;
        }
        else if(change === "siguiente"){
            newPage = page + 1;
            // filterRoutes();
            filterRoutes2();
        }
        setPage(newPage);
    }

    const resetState = (e) => {
        setResultados([]);
        setPage(0);
        setFiltro({
            nivelFisico: [],
            nivelTecnico: [],
            nivelSolar: [],
            altutudmsnm: [],
            horas: [],
        });
        unSelectAll();
    }

    useEffect(() => {

        const link_api = 'https://la-cumbre-cotidiana-api.herokuapp.com/api/ruta';
        if(!loadedData){
            try{
                axios.get(link_api)
                    .then(res => {
                        setRutas(res.data);
                        setLoadedData(true);
                    })
            }catch(error){
                console.log(error);
            } 
        }
        
     });

    let pageOneContent;
 
    pageOneContent = (
        <div className="planea-form-questions">
            <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]} flex justify="center">
                {opciones.nivelFisico && opciones.nivelFisico.map((opcion, idx) => {
                    let background;
                    if(opcion.selected){
                        background = "bg-green";
                    }
                    else{
                        background = "bg-grey";
                    }
                    return (
                        <Col key={idx} >
                            <div> 
                                <div className="planea-form-options center-content">
                                    <div>
                                        <p className="desnivel">{opcion.desnivel}</p>
                                        <img className="icon-dificultad" src={"img/Íconos/PNG fondo blanco/dificultades/" + opcion.opcion + ".png"} alt=""/>
                                    </div>
                                    <div className="img-icon-separator">
                                        <button className={"btn-form-opcion " + background} id={"nivelFisico"} value={opcion.valor} onClick={(e) => updateFilterObject(e)}>{opcion.opcion}</button>
                                    </div>
                                </div>
                            </div>  
                        </Col>
                    )
                })}
            </Row>
        </div>
    )


    let content;
    if(page === 0){
        content = (
            <div>
                <h2 className="planea-form-title comenzar-title mt-5">Hike Quiz</h2>
                <h4 className="planea-form-text">Completa el formulario y encuentra <br></br> la ruta que más buscas</h4>
            </div>
        )
    }
    else if(page === 1){
        content = (
            <div className="bottom-5">
                <h2 className="planea-form-title center-content center-content">¿Qué dificultad física prefieres?</h2>
                {pageOneContent}
               
            </div>
        )
    }
    else if(page === 2){
        content = (
            <div className="bottom-5">
                <h2 className="planea-form-title center-content">¿Qué nivel técnico buscas?</h2>
                <div className="d-flex planea-form-questions mb-5">
                    <Row gutter={[16,16]}>
                        {opciones.nivelTecnico && opciones.nivelTecnico.map((opcion, idx) => {
                            let background;
                            if(opcion.selected){
                                background = "bg-green";
                            }
                            else{
                                background = "bg-grey";
                            }

                            return (
                                <Col key={idx} xs={8} md={4}>
                                    <div> 
                                        <div className="planea-form-options">
                                            {/* img */}
                                            <div>
                                                <img className="icon-dificultad" src={"/img/Íconos/PNG fondo blanco/dificultades/" + opcion.opcion + ".png"} alt=""/>
                                            </div>
                                            <div className="img-icon-separator">
                                                <button className={"btn-form-opcion " + background} id={"nivelTecnico"} value={opcion.valor} onClick={(e) => updateFilterObject(e)}>{opcion.opcion}</button>
                                            </div>
                                        </div>
                                    </div>  
                                </Col>
                            )
                        })}
                    </Row>
                </div>
                
            </div>
        )
    }
    else if(page === 3){
        content = (
            <div>
                <h2 className="planea-form-title center-content">¿Cuánto tiempo tienes?</h2>
                <div style={{textAlign:"center"}}>
                    <img className="hourglass" src="img/Íconos/PNG fondo blanco/Reloj.png" alt=""/>
                </div>   
                <div className="planea-form-questions">
                    <Row gutter={[16,16]}>
                    {opciones.horas && opciones.horas.map((opcion, idx) => {
                            let background;
                            if(opcion.selected){
                                background = "bg-green";
                            }
                            else{
                                background = "";
                            }

                            return (
                                <Col span={12} key={idx}>
                                    <button className={"btn-form-transparent " +  background} id="horas" value={opcion.valor} onClick={(e) => updateFilterObject(e)}>{opcion.opcion}</button>
                                </Col>
                            )
                    })}
                    </Row>
                </div>
                <p className="planea-form-helper-text-long amarillo" style={{textAlign:"center",paddingTop:"2rem"}}>*Recuerda que cerros difíciles ocupan más de medio día.</p>
            </div>
        )
    }
    else if(page === 4){
        content = (
            <div>
                <h2 className="planea-form-title center-content">¿A qué altura le gustaría la cumbre?</h2>
                <div style={{textAlign:"center"}}>
                    <img className="hourglass" src="img/Íconos/PNG fondo blanco/Reloj.png" alt=""/>
                </div>   
                <div className="planea-form-questions mb-4">
                    <Row gutter={[16,16]}>
                        {opciones.altutudmsnm && opciones.altutudmsnm.map((opcion, idx) => {
                            let background;
                            if(opcion.selected){
                                background = "bg-green";
                            }
                            else{
                                background = "";
                            }

                            return (
                                <Col flex="auto" span={12} key={idx} style={{height:"100%"}}>
                                    <button style={{height:"100%"}} className={"btn-form-transparent " +  background} id="altutudmsnm" value={opcion.valor} onClick={(e) => updateFilterObject(e)}>{opcion.opcion}</button>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </div>
        )
    }
    else if(page === 5){
        content = (
            <div className="bottom-5">
                <h2 className="planea-form-title center-content">¿Qué tan expuesto al sol?</h2>
                <div style={{textAlign:"center"}}>
                    <img className="hourglass" src="img/Íconos/PNG fondo blanco/Reloj.png" alt=""/>
                </div>   
                <div className="planea-form-questions mb-5">
                    <Row>
                        {opciones.nivelSolar && opciones.nivelSolar.map((opcion, idx) => {
                            let background;
                            if(opcion.selected){
                                background = "bg-green";
                            }
                            else{
                                background = "";
                            }

                            return (
                                <Col flex="auto" span={8} key={idx} style={{height:"100%"}}>
                                    <button style={{height:"100%"}} className={"btn-form-transparent " +  background} id="nivelSolar" value={opcion.valor} onClick={(e) => updateFilterObject(e)}>{opcion.opcion}</button>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </div>
        )
    }
    else if(page === 6){
        content = (
            <div >
                <h2 className="planea-form-title comenzar-title center-content">¡Excelente, ya tienes tu ruta!</h2>
                <div className="mt-4">
                    <Row gutter={[16,16]}>
                    {resultados && resultados.map((resultado, idx) => {
                        return (
                            <Col  span={6} xs={24} sm={12} md={8} lg={6} key={resultado._id} className="tarjeta-ruta-centrada">
                                    <RouteCardRutas route={resultado} />
                            </Col>
                        )
                    })}
                    </Row>
                </div>
            </div>
        )
    }

    let navegacion;
    if(page === 0){
        navegacion = (
            <div className="center-boton">
                <button onClick={() => changePage("siguiente")} className="btn-form-amarillo    ">Comenzar</button>
            </div>
        )
        
    }
    else if(page > 0 && page < 6){
        navegacion = (
            <div className="center-boton">
                <div className="left">
                    <button onClick={() => changePage("atras")} className="btn-form-amarillo">Atrás</button>
                </div>
                <div className="right">
                    <button onClick={() => changePage("siguiente")} className="btn-form-amarillo">Siguiente</button>
                </div>
            </div>
        )
        
    }
    else{
        navegacion = (
            <div className="center-boton">
                <div className="left">
                    <button onClick={() => changePage("atras")} className="btn-form-amarillo">Atrás</button>
                </div>
                <div className="right">
                    <button onClick={() => resetState()} className="btn-form-amarillo">Volver a Empezar</button>
                </div>
            </div>

        ) 
    }

    let sidebar;
    let contentWidth;
    if(page > 0 && page < 6){
        contentWidth = "col-10 col-md-8";
        sidebar = (
                <div className="center-content">
                    <Title level={2} className="resultados-subtitle center-content">Algunos hikes posibles</Title>
                    <div className="mt-4">
                        <Row>
                        {resultados && resultados.map((resultado, idx) => {
                            return (
                                <Col  span={6} xs={24} sm={12} md={8} lg={24} key={resultado._id}>
                                <RouteCard route={resultado}/>
                                </Col>
                            )
                        })}
                        </Row>
                    </div>
                </div>
        );
    }
    else{
        sidebar = (
            null
        );
        contentWidth = "col-10 col-md-8";
    }

    return (
        <div>
            <Header/>
            {/* Notifications Toast*/}
            <Toaster 
                toastOptions={{
                    duration: 5000,
                    position: 'top-center',
                }}
            />
            <div className="background-color">
                <Card className='main-container-planea'>
               
                        <div className='Main'>
                            {content}
                        </div>

                        <div className='Botones'>
                            {navegacion}
                        
                        </div>
                        <div  className='Recomendaciones'>
                            {sidebar}


                        </div>
                      
                        
                </Card>
            </div>
            <Footer/>
        </div>
    )
}

export default Planea;
