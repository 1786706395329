import { Helmet } from 'react-helmet-async';

const hostName = "https://lacumbrecotidiana.pages.dev";

const HelmetComponent = ({titulo, descripcion, imagenURL, imageAlt}) => {
  return (
    <Helmet>
      { /* Standard metadata tags */ }
      <title>{titulo}</title>
      <meta name="title" content="La Cumbre Cotidianaa dinamico" data-react-helmet="true" data-rh="true"/>
      <meta name="description" content={descripcion} data-react-helmet="true" data-rh="true"/>
      { /* End standard metadata tags */ }
      { /* Facebook tags */ }
      <meta property="og:type" content={"article"} />
      <meta property="og:title" content={titulo} />
      <meta property="og:description" content={descripcion} />
      <meta property="og:image" content={imagenURL} />

      
      { /* End Facebook tags */ }
      { /* Twitter tags */ }
      <meta name="twitter:creator" content={"nombre"} />
      <meta name="twitter:card" content={"article"} />
      <meta name="twitter:title" content={titulo} />
      <meta name="twitter:description" content={descripcion} />
      { /* End Twitter tags */ }
          <meta name="theme-color" content="#008f68" />
    </Helmet>
  )
}

export default HelmetComponent