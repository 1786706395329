import { useState } from "react";

import "./footer.css";

import { Typography } from 'antd';
import { Col, Row } from 'antd';
import { Input } from 'antd';
import { InstagramFilled, FacebookFilled, YoutubeFilled, EnvironmentFilled } from '@ant-design/icons';

const { Title } = Typography;

const Footer = () => {
    const [email, setEmail] = useState('')
    const [blockEmail, setBlockEmail] = useState(true); //cambiar a false cuando arregle lo de mandar el email

    const sendEmail = ((e) => {
        setBlockEmail(true);
        console.log(e);
    })
    return (
        <div class>
            <div className="newsletter">
                <Row className="newsletterContent">
                    <Col span={12}>
                        <Title level={4} className="newsletterTitle" style={{ color: "whitesmoke", fontWeight: "normal" }}>¡Suscríbete para ser el primero en enterarte de nuestros eventos y rutas nuevas!</Title>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={16}>
                                <div className="suscribir-container">
                                    <Input disabled={blockEmail} placeholder={blockEmail ? 'Suscrito!' : "Correo Electronico"} className="dashboardSearch" onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="search-side-button" onClick={sendEmail}>
                                    <p style={{ fontSize: '16px', color: 'white', margin: "auto" }}>Suscribir</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="mountain-container">
                </div>
            </div>


            <div className="footer">
                <Row className="footerContent">
                    <Col span={6} xs={8} md={6} >
                        <p className="titleFooter">Explorar</p>
                        <p className="subTextFooter" ><a href="/rutas" style={{color:"inherit"}}>Rutas</a></p>
                        <p className="subTextFooter" ><a href="/planea" style={{color:"inherit"}}>Planea</a></p>
                        <p className="subTextFooter" ><a href="https://www.wikiloc.com/wikiloc/user.do?id=4675508" rel="noreferrer" target="_blank" style={{color:"inherit"}}>Wikiloc</a></p>
                     </Col>
                     <Col span={6} xs={8} md={6} >
                         <p className="titleFooter">Nosotros</p>
                         <p className="subTextFooter"><a href="/mision" style={{color:"inherit"}}>Nuestro equipo</a></p>
                         <p className="subTextFooter"><a href="/mision" style={{color:"inherit"}}>Misión</a></p>
                      </Col>
                      <Col span={6} xs={8} md={6} >
                          <p className="titleFooter">Únete</p>
                          <p className="subTextFooter"><a href="/oportunidades" style={{color:"inherit"}}>Oportunidades</a></p>
                       </Col>
                       <Col span={6} xs={24} md={6} >
                        <p className="titleFooter">La Cumbre Cotidiana A.C.</p>
                        <p className="emailFooter"><a href="mailto:contacto@lacumbrecotidiana.org" style={{ color: "inherit" }}>contacto@lacumbrecotidiana.org</a></p>
                        <Row gutter={[8, 8]}>
                            <Col span={6}>
                                <p className="subTextFooter"><a href="https://www.instagram.com/lacumbrecotidiana/"><InstagramFilled className="footerLogos" /></a></p>
                            </Col>
                            <Col span={6}>
                                <p className="subTextFooter"><a href="https://www.youtube.com/channel/UCT6hkV4O5gVqvnSlESH-vNg"><YoutubeFilled className="footerLogos" /></a></p>
                            </Col>
                            <Col span={6}>
                                <p className="subTextFooter"><a href="https://www.facebook.com/Lacumbrehiking"><FacebookFilled className="footerLogos" /></a></p>
                            </Col>
                            <Col span={6}>
                                <p className="subTextFooter"><a href="https://www.wikiloc.com/wikiloc/user.do?id=4675508"><EnvironmentFilled className="footerLogos" /></a></p>
                            </Col>
                        </Row>
                        <p className="text-copyright">
                            @Copyright 2022 La Cumbre Cotidiana A.C.
                        </p>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Footer;