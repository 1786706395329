import {useState} from 'react'
import {Row, Col, Image} from 'antd'

import aguaIcon from './iconos/aguaIcon.svg'
import cumbreIcon from './iconos/cumbreIcon.svg'
import recorridoIcon from './iconos/recorridoIcon.svg'
import tecnicoIcon from './iconos/tecnicoIcon.svg'

import './tipoHike.css'


const TipoHike = ({tipoHike, setTipoHike}) => {
  


  return (
    <>
    <h1 className='titulo'>¿Qué tipo de hike deseas?</h1>
    <h2 className='subtitulo'>(Selecciona todos los que te interesen)</h2>

    <Row>
      <Col span={12} md={6}>
        <div className={'iconos ' + (tipoHike.includes('agua') ? "true" : " ")} 
        onClick={(e) => {
          setTipoHike((tipoHike.includes('agua') ? (tipoHike.filter(ee => ee !== 'agua')) : ( [...tipoHike, ...['agua']])))
        }}>
          <Image 
              src={aguaIcon} 
              fluid 
              alt={`aguaIcon`} 
              preview={false}
          />
        </div>

        <h1 className={'subtitulos-icons ' + (tipoHike.includes('agua') ? "subtitulos-true" : " ")} >
          Con Agua
        </h1>
      </Col>
      <Col span={12} md={6}>
        <div className={'iconos ' + (tipoHike.includes('cumbre') ? "true" : " ")} 
        onClick={(e) => {
          setTipoHike((tipoHike.includes('cumbre') ? (tipoHike.filter(ee => ee !== 'cumbre')) : ( [...tipoHike, ...['cumbre']])))
        }}>
          <Image 
              src={cumbreIcon} 
              fluid 
              alt={`cumbreIcon`} 
              preview={false}
          />
        </div>
        <h1 className={'subtitulos-icons ' + (tipoHike.includes('cumbre') ? "subtitulos-true" : " ")} >
          cumbre
        </h1>
      </Col>
      <Col span={12} md={6}>
        <div className={'iconos ' + (tipoHike.includes('recorrido') ? "true" : " ")} 
        onClick={(e) => {
          setTipoHike((tipoHike.includes('recorrido') ? (tipoHike.filter(ee => ee !== 'recorrido')) : ( [...tipoHike, ...['recorrido']])))
        }}>
          <Image 
              src={recorridoIcon} 
              fluid 
              alt={`recorridoIcon`} 
              preview={false}
          />
        </div>
        
        <h1 className={'subtitulos-icons ' + (tipoHike.includes('recorrido') ? "subtitulos-true" : " ")} >
          recorrido
        </h1>
      </Col>
      <Col span={12} md={6}>
        <div className={'iconos ' + (tipoHike.includes('neva') ? "true" : " ")} 
        onClick={(e) => {
          setTipoHike((tipoHike.includes('neva') ? (tipoHike.filter(ee => ee !== 'neva')) : ( [...tipoHike, ...['neva']])))
        }}>
          <Image 
              src={tecnicoIcon} 
              fluid 
              alt={`tecnicoIcon`} 
              preview={false}
          />
        </div>
        <h1 className={'subtitulos-icons ' + (tipoHike.includes('neva') ? "subtitulos-true" : " ")} >
          Con nieve
        </h1>
      </Col>
    </Row>
      
    </>
  )
}

export default TipoHike