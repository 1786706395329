import React, { Component } from "react";
import {useEffect} from 'react';

import './progressBar.css';

const ProgressBar = () => {
    useEffect(() => {
    const scrollline = document.querySelector('.progress-bar');
    const body = document.getElementById('root');
    const nav = document.querySelector('.navbar');
    const fillscrollline = event => {
        const windowHeight = window.innerHeight;
        const fullHeight = body.clientHeight;
        const scrolled = window.scrollY;
        const percentScrolled = (scrolled / (fullHeight - windowHeight)) * 100;

        scrollline.style.width = percentScrolled + '%';
        scrollline.style.top = nav.offsetHeight + 'px';
      };
  
      window.addEventListener('scroll', fillscrollline);
  
      return () => {
        window.removeEventListener('scroll', fillscrollline);
      };

    }, []);
  
    return (
      <div className="progress-bar"></div>
    );
  };
  
  export default ProgressBar;