import { Col, Row, Tabs } from 'antd';
import Image from 'react-bootstrap/Image';
import { Typography } from 'antd';

import 'antd/dist/antd.less'; // or 'antd/dist/antd.less'

import './comoVereda.css';

const { Title } = Typography;
const { TabPane } = Tabs;

const descripcionTecnica = [ 
    "No requiere experiencia técnica.",
    "Contiene tramos con más de 40 grados de inclinación y/o partes del caminos son inestables.",
    "Contiene chorreaderos de piedras peligrosos y/o requiere de uso de tus manos.",
    "Contiene tramos de escalada libre vertical de más de 3 metros.",
    "Requiere utilizar equipo de segurdad en puntos de seguridad previamente armados.",
    "Requiere utilizar equipo de escalada para ascender y descender una ruta armada.",
    "Requiere utilizar equipo de seguridad de escalada tradicional."
  ];

  const descripcionCorta = [
    "No requiere experiencia",
    "Partes empinadas",
    "Chorreadero peligroso",
    "Escaladas verticales",
    "Equipo de seguridad",
    "Equipo de escalada",
    "Escalada tradicional"
  ]

const descripcionSolar = [
    "Mucha Sombra",
    "Algo De Sombra",
    "Muy Poca Sombra"
];

const ComoVereda = ({ruta, isMobile}) => {
  
  return (
    <div id="Vereda" className="comoEsLaVereda">
        <h1 className="comoVeredaTitulo">¿Cómo es la vereda?</h1>
        <Tabs tabPosition='top' size={isMobile ? ('default') : ('large')} className="margin-top">
            <TabPane tab="Nivel Físico" key="1">
                <Row gutter={16}>
                    <Col span={6}>
                        <div>
                            <Image src={`/img/Íconos/PNG fondo blanco/dificultades/${ruta.nivelFisico}.png`} fluid alt="dificultad fisica" className="iconoComoEsLaVereda"/>
                        </div>
                    </Col>
                    <Col span={18}>
                        <Title level={4}><b>Altura: </b> {ruta.altutudmsnm} msnm</Title>
                        <Title level={4}><b>Desnivel: </b> {ruta.desnivel} m</Title>
                        {!isMobile && (<p className="texto-dificultades">{ruta.dificultadFisica}</p>)}
                    </Col>
                    {isMobile && (
                        <Col span={24} md={24}>
                            <div>
                            <p className="texto-dificultades">{ruta.dificultadFisica}</p>
                            </div>
                        </Col>)}
                </Row>
            </TabPane>
            <TabPane tab="Nivel Técnico" key="2">
                <Row gutter={16}>
                    <Col span={6} md={6}>
                        <div>
                            <Image src={`/img/Íconos/PNG fondo blanco/dificultades/Tecnico${ruta.nivelTecnico}.png`} fluid alt="dificultad fisica" className="iconoComoEsLaVereda"/>
                        </div>
                    </Col>
                    <Col span={18}>
                        <Title level={3}>{descripcionCorta[ruta.nivelTecnico-1]}</Title>
                        {!isMobile && (<p className="texto-dificultades">{ruta.dificultadTecnica}</p>)}
                    </Col>
                    {isMobile && (
                    <Col span={24}>
                        <div>
                            <p className="texto-dificultades">{ruta.dificultadTecnica}</p>
                        </div>
                    </Col>)}
                </Row>
            </TabPane>
            <TabPane tab="Exposicion Solar" key="3">
                <Row gutter={16}>
                    <Col span={6} md={6}>
                        <div>
                            <Image src={`/img/Íconos/PNG fondo blanco/dificultades/Sol${ruta.nivelSolar}.png`} fluid alt="dificultad fisica" className="iconoComoEsLaVereda"/>
                        </div>
                    </Col>
                    <Col span={18}>
                        <Title level={3}>{descripcionSolar[ruta.nivelSolar-1]}</Title>
                        {!isMobile && (<p className="texto-dificultades">{ruta.exposicionSolar}</p>)}
                    </Col>
                    {isMobile && (
                    <Col span={24}>
                        <div>
                            <p className="texto-dificultades">{ruta.exposicionSolar}</p>
                        </div>
                    </Col>)}
                </Row>
            </TabPane>
        </Tabs>
    </div>
  )
}

export default ComoVereda