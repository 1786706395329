import { React, useEffect, useState } from "react";
import Footer from '../common/Footer';
import Header from '../common/Header';

import 'antd/dist/antd.min.css'; 
import './Rutas.css';
import SearchBar from "./searchBar";
import Banners from "./banners";
import Popovers from "./popovers";
import Parallaxx from "../common/Parallaxx"
import HelmetComponent from "../common/helmet/helmet";
import CardsRutas from "./cardsRutas";
import { BackTop } from 'antd';
import { Anchor } from 'antd';



import { Col, Row, Layout } from 'antd';
const { Content } = Layout;
const backgroundImageRutaUrl = "https://cdnlcc.ga/ruta-header.webp";

const { Link } = Anchor;

const Rutas = () => {
  const [isMobile, setIsMobile] = useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize();
  });
  
  const [orden, setOrden] = useState('nombre');
  const [routes, setRoutes] = useState([]);
  const [showRoutes, setShowRoutes] = useState([]);

  return (
    <>
      <HelmetComponent 
        titulo={"Rutas de Senderismo"} 
        descripcion={"Conoce las rutas de senderismo disponibles en la ciudad de Monterrey"} 
        imagenURL={backgroundImageRutaUrl} 
        imageAlt={"Senderistas caminando en la sierra de Arteaga, Coahuila"} 
      />
    <div style={{backgroundColor : '#efefef'}}>
        <Header />
        {/* Parallax */}
        <Parallaxx backgroundImageRutaUrl={backgroundImageRutaUrl} titulo={'Rutas de Senderismo'} subtitulo={'Explora El Camino'}/>

        <Content className="contentRutas">
        {/*<BackTop  /> */}
          {/* Menu Rutas */}
          
          <Row gutter={[16, 16]}>
            <Col span={24} md={{span: 16}}>{/* Search Rutas cambiar span a 8 cuando se agregen los banners*/}
              <SearchBar setRoutes={setRoutes} setOrden={setOrden}/>
            </Col>
            {!isMobile && <Banners />}
          </Row>

          <Popovers setShowRoutes={setShowRoutes} routes={routes} orden={orden} setOrden={setOrden}/> 
          <CardsRutas showRoutes={showRoutes}/>
        </Content>
        <br/><br/><br/>
        <Footer />
    </div>
    </>
  );
};
export default Rutas;