import { useState, useEffect } from "react";

import 'antd/dist/antd.min.css'; 
import './searchBar.css';

import { Input } from 'antd';
import { Col, Row, Typography } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import axios from 'axios';
import Fuse from 'fuse.js'

const link_api = "https://us-east-1.aws.data.mongodb-api.com/app/website-zmlpn/endpoint";
const { Title } = Typography;

const SearchBar = ({setRoutes, setOrden}) => {
    const [allRoutes, setAllRoutes] = useState([]);
    const fuse = new Fuse(allRoutes, {keys: [{name: "nombre", weight: 1}, {name: "area", weight: 1}], includeScore: true});
    
    const searchWithFuse = (query) => {
      if (!query) {
        return [];
      }
      setOrden('nombre');
      const lista = (fuse.search(query).map(item => {return(item.item)}));
      setRoutes(lista);
    }


    const llenarRutas = () => {
        try {
          axios.get(link_api + "/rutas")
            .then(res => {
              setAllRoutes(res.data);
              setRoutes(res.data);
            })
        } catch (error) {
          console.log(error);
        }
      }

    useEffect(() => {
      llenarRutas();
    }, [])

  return (
    <Row>
        <Col span={24} className="prueba">
            <Title style={{fontFamily: 'Bebas Neue,cursive'}} className="subtitle" level={3}>Encuentra tu ruta</Title>
        </Col>
        <Col>
          <Row>
              <Col span={20}>
                  <div className="my-search-container">
                      <Input
                          defaultValue={''}
                          placeholder="Buscar"
                          onChange={e => {(searchWithFuse(e.target.value))}}
                          className="dashboardSearch"
                          size="large"
                      />
                  </div>
              </Col>
              <Col span={4}>
                  <div className="search-icon">
                      <SearchOutlined style={{ fontSize: '24px', color: 'white', margin:'auto' }}/>
                  </div>
              </Col>
          </Row>
        </Col>
    </Row>
  )
}

export default SearchBar