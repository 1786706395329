import {useState, useEffect} from 'react'
import {Image, Row, Col} from 'antd'

import './tiempo.css'

import reloj from './iconos/reloj.svg'

const TiempoHike = ({tiempo, setTiempo}) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth <= 650) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
}

// Reactive window resize
useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize()
})
  return (
    <div>
      <h1 className='titulo'>¿Cuánto tiempo tienes?</h1>
      <h2 className='subtitulo'>Recuerda que cerros difíciles ocupan más de medio día. Si escogiste dificultades altas escoge "Tengo el día entero" para mejorar tus resultados.</h2>
    
      <div className='cuadro-reloj'>
        <Row justify='space-between'>
          <Col span={8}>
            <div className='cuadro-reloj-opcion'>
              <div className={'cuadro-reloj-opcion-button ' + (!tiempo && "cuadro-reloj-opcion-button-true")} onClick={(() => {setTiempo(false)})}>
                <h3 className='textoo'>Sólo tengo medio día</h3>
              </div>
              
            </div>
          </Col>
          <Col span={8}>
          <Image 
                  src={reloj} 
                  fluid 
                  alt={`reloj`} 
                  preview={false}
              />
          </Col>
          <Col span={8}>
            <div className='cuadro-reloj-opcion'>
              <div className={'cuadro-reloj-opcion-button ' + (tiempo && "cuadro-reloj-opcion-button-true")}  onClick={(() => {setTiempo(true)})}>
              <h3 className='textoo'>Tengo el día entero</h3>
                </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default TiempoHike