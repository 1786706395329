import {useState, useEffect} from 'react'
import {Image, Row, Col} from 'antd'

import './tecnico.css'

const descripcion = [
  "No requiere experiencia técnica.",
  "Contiene tramos con más de 40 grados de inclinación y/o partes del caminos son inestables.",
  "Contiene chorreaderos de piedras peligrosos y/o requiere de uso de tus manos,",
  "Contiene tramos de escalada libre vertical de más de 3 metros.",
  "Requiere utilizar equipo de segurdad en puntos de seguridad previamente armados.",
  "Requiere utilizar equipo de escalada para ascender y descender una ruta armada.",
  "Requiere utilizar equipo de seguridad de escalada tradicional."
];

const descripcionCorta = [
  "No requiere experiencia",
  "Partes empinadas",
  "Chorreadero peligroso",
  "Escaladas verticales",
  "Equipo de seguridad",
  "Saber puntear",
  "Escalada tradicional"
]

const TecnicoHike = ({tecnico, setTecnico}) => {
  const [iconos, setIconos] = useState([{}]);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth <= 650) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
}

// Reactive window resize
useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize()
})


  useEffect(() => {
    let lista = [];
    for(let i = 1; i<=7; i++){
      lista.push({"icon" :"img/Íconos/PNG fondo blanco/dificultades/SVG/" + (String.fromCharCode(64+i)) + ".svg",
                  "letra": i,
                  "descripcion" : descripcionCorta[i-1]});
    }
    setIconos(lista);
  }, [])


  return (
    <>
    
    <h1 className='titulo'>¿Qué nivel técnico buscas?</h1>
    { !isMobile &&
      <h2 className='subtitulo'>Un cerro puede tener poco desnivel pero ser muy técnico. Para prevenir accidentes, selecciona solo lo que sí sabes hacer.</h2>
    }
    <Row gutter={[8,8]}>
      {iconos.map((e) => {return (
      <Col span={6}>
        <div className='center-image'>
          <Image 
            className={(tecnico.includes(e.letra)) ? '' : 'gray'}
            preview={false}
            src={e.icon} 
            fluid 
            alt={`Icono Dificultad`} 
            onClick={() => {
              if(tecnico.includes(e.letra)) setTecnico(tecnico.filter(ee => ee !== e.letra))
              else setTecnico([...tecnico, ...[e.letra]])
            }}
          />
        </div>
        <p className='rango-dificultades'>{e.descripcion}</p>

            <div className={(tecnico.includes(e.letra)) ? 'button-div checked' : 'button-div' } onClick={() => {
              if(tecnico.includes(e.letra)) setTecnico(tecnico.filter(ee => ee !== e.letra))
              else setTecnico([...tecnico, ...[e.letra]])
            }}>
              {(tecnico.includes(e.letra)) ? 
              (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>)
            :
              (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="black">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>)
            }
            </div>
      </Col>
      )})}
    </Row>
    </>
  )
}

export default TecnicoHike