import {useState, useEffect} from 'react'
import {Image, Row, Col} from 'antd'

import './vehiculo.css'

import carro from './iconos/carro.svg'
import camioneta from './iconos/camioneta.svg'

const VehiculoHike = ({vehiculo, setVehiculo}) => {
  return (
    <div>
    <h1 className='titulo'>¿Tienes camioneta o carro?</h1>
    <div className='cuadro-altitud'>
      <Row>
        <Col span={12} className='vehiculo-opcion' onClick={((e) => {setVehiculo(false)})}>
          <div className='margin'>
            <Image 
              className='icono-vehiculo'
              src={carro} 
              fluid 
              alt={`reloj`} 
              preview={false}
            />
            <div className={(vehiculo ? ('vehiculo-opcion-button') : ('vehiculo-opcion-button-true'))}>
              <p className="vehiculo-opcion-button-text">
                Carro
              </p>
            </div>
          </div>

        </Col>

        
        <Col span={12} className='vehiculo-opcion' onClick={((e) => {setVehiculo(true)})}>
        <div className='margin'>
            <Image 
              className='icono-vehiculo'
              src={camioneta} 
              fluid 
              alt={`reloj`} 
              preview={false}
            />
            <div className={(!vehiculo ? ('vehiculo-opcion-button') : ('vehiculo-opcion-button-true'))}>
              <p className="vehiculo-opcion-button-text">
                Camioneta
              </p>
            </div>
          </div>
        
        </Col>
      </Row>
    </div>
      
    </div>
  )
}

export default VehiculoHike