import React, { Component } from "react";
import {useEffect} from 'react';

import './animator.css';

const Animator = () => {
    useEffect(() => {
    const fades = document.querySelectorAll('.animate');
    const animate = event => {
        fades.forEach(fade => {
          const sectionTop = fade.offsetTop;
          //console.log('section-top',sectionTop);
          if(window.scrollY >= (sectionTop - 600)){
            if(fade.classList.contains('fade-r')){
              fade.classList.remove('fade-r')
              fade.classList.add('fade-right')
            }else if(fade.classList.contains('fade-l')){
              fade.classList.remove('fade-l')
              fade.classList.add('fade-left')
            }else if(fade.classList.contains('fade-t')){
              fade.classList.remove('fade-t')
              fade.classList.add('fade-top')
            }else if(fade.classList.contains('fade-b')){
              fade.classList.remove('fade-b')
              fade.classList.add('fade-bottom')
            }
          }
        })
      };
  
      window.addEventListener('scroll', animate);
  
      return () => {
        window.removeEventListener('scroll', animate);
      };

    }, []);
  
    return (
        <span></span>
    );
  };
  
  export default Animator;