import {React,useEffect,useState,createRef} from "react";
import { Col, Row, Input, Tabs  } from 'antd';
import Image from 'react-bootstrap/Image';
import { Anchor } from 'antd';
import { Typography } from 'antd';

import 'antd/dist/antd.less'; // or 'antd/dist/antd.less'

import './informacionBasica.css';
const { Title } = Typography;
const { Paragraph } = Typography;


const InformacionBasica = ({ruta, isMobile}) => {
    
    const [showAdvertencias, setShowAdvertencias] = useState([]);

    useEffect(() => {
        let index = 0;
        const ads = ruta.advertencias;

        let anss = [];
        if(ads){
        ads.forEach(element => {
            anss.push(
            <Col span={6} key={index++} xs={8} sm={6} md={6}>
                <Image src={`/img/Íconos/PNG sin fondo/${element.nombre}.png`} fluid alt={element.nombre} className="fichaContenidoAdvertenciaIcono"/>
            </Col>)
            });
        }
        setShowAdvertencias(anss);
        
    }, [ruta]);

    const tiempo = () => {
        let tiempo = "";

        if(ruta.minutos && ruta.horas){
            //convert minutes to hours
            let minutesToHours = ruta.minutos / 60;

            tiempo = (ruta.horas+minutesToHours) + " hrs";

        }
        else if(ruta.horas){
            tiempo = ruta.horas + " hrs";
        }
        else if(ruta.minutos){
            tiempo = ruta.minutos + " min";
        }
        return tiempo;
    }

  return (
    <>
        <div id="Basicos" className="informacionBasicaRuta">
            <Row gutter={8}>
                <Col xs={24} sm={24} md={10} lg={12} xl={10}>
                    <h1 className="informacionBasicaTitulo">{ruta.nombre}</h1>
                    <div className="informacionBasicaContainerUbicacion">
                        <Image src="/img/Íconos/ubicacion.png" fluid alt="ubicacion de la ruta" className="ubicacionRutaMini"/>
                        <b>{ruta.area}</b>, {ruta.municipio}
                    </div>
                </Col>
                <Col xs={4} sm={4} md={4} lg={2} xl={4} style={{textAlign:"right"}}>
                    <Image src="/img/Íconos/caminante.png" fluid alt="caminante" className="caminanteRuta"/>
                </Col>
                <Col xs={18} sm={18} md={10} lg={8} xl={10}>
                    <div className="informacionBasicaRutaTexto">
                        <p>{ruta.seo}</p>
                    </div>
                </Col>
            </Row>
            <Row  gutter={12} style={{paddingTop:"1rem"}} type="flex">
                <Col span={6} xs={24} md={6} className="containerInformaciones">
                    <div className="fichaTecnicaRuta">
                        <div className="fichaTitulo">
                            <Title level={4} className="fichaTituloTexto">Ficha Técnica</Title>
                        </div>
                        <div className="fichaContenido">
                            <div className="fichaContenidoTexto">
                                <Image src="/img/Íconos/caminante.png" fluid alt="caminante" className="fichaContenidoIcono"/>
                                <b>Distancia : </b>{ruta.distancia} km
                            </div>
                            <div className="fichaContenidoTexto">
                                <Image src="/img/Íconos/tiempo2.png" fluid alt="tiempo" className="fichaContenidoIcono"/>
                                <b>Tiempo : </b>{tiempo()}
                            </div>

                            <div className="fichaContenidoTexto">
                                <Image src="/img/Íconos/altura.png" fluid alt="tiempo" className="fichaContenidoIcono"/>
                                <b>Altitud: </b>{ruta.altutudmsnm} m
                            </div>
                            <div className="fichaContenidoTexto">
                                <Image src="/img/Íconos/desnivel.png" fluid alt="tiempo" className="fichaContenidoIcono"/>
                                <b>Desnivel: </b>{ruta.desnivel} m
                            </div>

                        </div>
                    </div>
                </Col>
                <Col span={8} xs={24} md={8} className="containerInformaciones">
                    <div className="fichaTecnicaRutaLlevar">

                        <div className="fichaTituloLlevar">
                            <Title level={4} className="fichaTituloTexto">¿Qué llevar?</Title>
                        </div>
                        <div>
                            <div>
                            <Paragraph className="fichaContenidoTextoLlevar"
                                ellipsis={{
                                rows : 10,
                                expandable: true,
                                symbol: 'mas info',
                                }}
                            >
                                {ruta.alimentosLiquidos} <br/><br/> {ruta.vestimenta}
                            </Paragraph>
                            </div>
                            
                        </div>
                    </div>
                </Col>
                <Col span={10} xs={24} md={10} className="containerInformaciones">
                    <div className="fichaTecnicaRutaAdvertencias">
                        <div className="fichaTituloAdvertencias">
                            <Title level={4} className="fichaTituloTexto">Advertencias</Title>
                        </div>
                        <div className="fichaContenido">
                            <Row gutter={16}>
                                {/*Insert advertenciasImagenes image from ruta in col with span 6*/}
                                {showAdvertencias}
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </>
  )
}

export default InformacionBasica