import Pregunta from "./pregunta";
import Respuesta from "./respuesta";
import { Col, Row } from 'antd';

function FaqElement(props) {
    return (
        <Col span={12}>
            <Row>
                <Col span={2}>
                    <h1>{props.num}: </h1>
                </Col>
                <Col>
                    <Pregunta pregunta={props.pregunta} />
                    <Respuesta respuesta={props.respuesta} />
                </Col>
            </Row>
        </Col>
    );
}

export default FaqElement;